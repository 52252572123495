import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function CorteNuevo() {
  const { user } = useContext(AuthContext);
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [totalTotalEfectivo, setTotalTotalEfectivo] = useState(0);
  const [totalTotalTDC, setTotalTotalTDC] = useState(0);
  const [totalTotalComisionTDC, setTotalTotalComisionTDC] = useState(0);
  const [totalTotalTransfer, setTotalTotalTransfer] = useState(0);
  const [totalTotalCortesia, setTotalTotalCortesia] = useState(0);
  const [totalTotalTotal, setTotalTotalTotal] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    if(user.areas){
      axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiario/${user.areas}/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .map((a) => {
              if(a.ventasTiendas!=""){
              return {
                fecha: a.fecha,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
              }}else{
                return {
                  fecha: a.fecha,
                  total: a.total,
                  efectivo: a.efectivo,
                  tdc: a.tdc,
                  transfer: a.transfer,
                } 
              }
            })
            .filter(function (el) {
              return el != null;
            });

            let agrupado = arrayTabla.reduce(function (groups, item) {
              const val = item["fecha"];
              groups[val] = groups[val] || {
                fecha: item.fecha,
                total: 0,
                efectivo: 0,
                tdc: 0,
                transfer: 0,
              };
              groups[val].fecha = item.fecha;
              groups[val].total += item.total;
              groups[val].efectivo += item.efectivo;
              groups[val].tdc += item.tdc;
              groups[val].transfer += item.transfer;
              return groups;
            }, []);
      
            let data = Object.values(agrupado);

          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
   
  }

  }, [user, selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    const data = comments.map((a) => {{
        return [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Corte de Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
     
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`AbonosTiendas.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {{
        return {
          Efectivo: a.efectivo,
          TDC: a.tdc,
          Transfer: a.transfer,
          Total: a.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Corte de Tienda";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Corte de Tienda",
        sheetFilter: [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total",
        ],
        sheetHeader: [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {{
      return [
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
      ];
    }
  });

  const dataPDF = Object.values(data);

  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });

  const doc = new jsPDF("landscape");
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 140, 15, 25, 25);
  doc.text(`Corte de Tiendas`, 20, 30);
  doc.text(`${selectedFechaInicio}`, 20, 40);
  doc.autoTable({
    head: [
      [
        "Efectivo",
        "TDC",
        "Transfer",
        "Total",
      ],
    ],
    body: dataPDFLimpia,
    startY: 45,
   
  });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ventas",
          email: mailTo,
          fileName: "ListadoVentas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Corte de Tienda.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Efectivo", field: "Efectivo", sortable: false },
    { name: "TDC", field: "tdc", sortable: false },
    { name: "Transfer", field: "transfer", sortable: false },
    { name: "Total", field: "total", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.total.toString().includes(search) ||
          comment.efectivo.toString().includes(search) ||
          comment.transfer.toString().includes(search) ||
          comment.tdc.toString().includes(search) 
      );
    }
   



    setTotalItems(computedComments.length);

   

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
  ]);



  let totalEfectivo = 0
  let totalTDC = 0
  let totalTransfer = 0
  let totalTotal = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.corteTiendas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
             
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Abonos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Corte de Tienda</h3>
       
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                  totalEfectivo = totalEfectivo + a.efectivo
                  totalTDC = totalTDC + a.tdc
                  totalTransfer = totalTransfer + a.transfer
                  totalTotal = totalTotal + a.total
                  return (
                    <tr>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}
                      </td>
                      
                    </tr>
                  );
              })}
            
            </tbody>
          </Table>
         
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default CorteNuevo;
