import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesDashboards() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
          <Row>
          {/* <Col md={4} align="center">
                    {user.menu_reportes ?(
                      <Button href="/DashboardCXP" className="botonesMenu" color="success">
                        <i class="fas fa-chart-bar fa-7x"></i>
                        <br />
                        <br />
                        Dashboard CXP
                      </Button>
                    ): <Button disabled className="botonesMenu" color="success">
                    <i class="fas fa-chart-bar fa-7x"></i>
                    <br />
                    <br />
                    Dashboard CXP
                  </Button> }
                    </Col> */}

                    <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/DashboardInventarios" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              Dashboard Inventarios
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tshirt fa-7x"></i>
          <br />
          <br />
          Dashboard Inventarios
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/DashboardVentas" className="botonesMenu" color="success">
               <i class="fas fa-signal fa-7x"></i>
              <br />
              <br />
              Dashboard Ventas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
           <i class="fas fa-signal fa-7x"></i>
          <br />
          <br />
          Dashboard Ventas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/Balance" className="botonesMenu" color="success">
               <i class="fas fa-chart-line fa-7x"></i>
              <br />
              <br />
              Balance
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
           <i class="fas fa-chart-line fa-7x"></i>
          <br />
          <br />
          Balance
        </Button> }
          </Col>

          </Row>
            <br />
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesDashboards;
