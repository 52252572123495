import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportesAdmin() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
          <Row>
          <Col md={4} align="center">
                    {user.edoCtaClientes ?(
                      <Button href="/CalificadoraClientes" className="botonesMenu" color="success">
                        <i class="fas fa-exclamation-circle fa-7x"></i>
                        <br />
                        <br />
                        Calificadora Clientes
                      </Button>
                    ): <Button disabled className="botonesMenu" color="success">
                    <i class="fas fa-exclamation-circle fa-7x"></i>
                    <br />
                    <br />
                    Calificadora Clientes
                  </Button> }
                    </Col>
                    <Col md={4} align="center">
                    {user.edoCtaClientes ?(
                      <Button href="/ReporteFlujoEfectivo" className="botonesMenu" color="success">
                        <i class="fas fa-dollar-sign fa-7x"></i>
                        <br />
                        <br />
                        Flujo Efectivo
                      </Button>
                    ): <Button disabled className="botonesMenu" color="success">
                    <i class="fas fa-dollar-sign fa-7x"></i>
                    <br />
                    <br />
                    Flujo Efectivo
                  </Button> }
                    </Col>
            <Col md={4} align="center">
                    {user.edoCtaClientes ?(
                      <Button href="/ReporteFlujoEfectivo2" className="botonesMenu" color="success">
                        <i class="fas fa-dollar-sign fa-7x"></i>
                        <br />
                        <br />
                        Flujo Efectivo 2
                      </Button>
                    ): <Button disabled className="botonesMenu" color="success">
                    <i class="fas fa-dollar-sign fa-7x"></i>
                    <br />
                    <br />
                    Flujo Efectivo 2
                  </Button> }
                    </Col>
            </Row>
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportesAdmin;
