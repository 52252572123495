import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faFacebook, fas } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faHome,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Password from "../Login/Password";

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faFacebook,
  faHome,
  faEnvelope,
  faPhoneAlt
);

const Header = (props) => {
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CAMARONES = process.env.REACT_APP_AREAS_CAMARONES;
  const AREAS_TOMATLAN = process.env.REACT_APP_AREAS_TOMATLAN;
  const AREAS_COCO = process.env.REACT_APP_AREAS_COCO;
  const AREAS_SN_ANTONIO = process.env.REACT_APP_AREAS_SN_ANTONIO;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
          {!user.menu_edoCta_Cliente && !user.menu_cobradores ? (
            <>
              <Navbar color="dark" dark expand="lg">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="mr-auto" navbar>
                    {user.areas != AREAS_TOMATLAN &&
                    user.areas != AREAS_COCO &&
                    user.areas != AREAS_SN_ANTONIO &&
                    user.areas != AREAS_PLAZA ? (
                      <>
                        {user.menu_articulos &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuArticulos"
                            >
                              MODELOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            MODELOS
                          </NavLink>
                        )}

                        {user.menu_compras &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuCompras">
                              COMPRAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            COMPRAS
                          </NavLink>
                        )}

                        {user.menu_ventas &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuVentas">
                              VENTAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            VENTAS
                          </NavLink>
                        )}

                        {user.menuTiendas && AREAS_CAMARONES != user.areas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuTiendas">
                              TIENDAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            TIENDAS
                          </NavLink>
                        )}

                        {user.menu_inventarios &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuInventarios"
                            >
                              INVENTARIOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            INVENTARIOS
                          </NavLink>
                        )}
                        {user.menu_admin &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuGastos">
                              GASTOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            GASTOS
                          </NavLink>
                        )}
                        {user.menu_empleados &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuNominas">
                              EMPLEADOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            EMPLEADOS
                          </NavLink>
                        )}
                        {user.configAdmin &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuAdmin">
                              ADMIN
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            ADMIN
                          </NavLink>
                        )}

                        {user.pagoProveedores &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuCXP">
                              CXP
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            CXP
                          </NavLink>
                        )}

                        {user.configuracion &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuConfiguraciones"
                            >
                              CONFIG
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            CONFIG
                          </NavLink>
                        )}

                        {user.menu_reportes &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuReportes"
                            >
                              REPORTES
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            REPORTES
                          </NavLink>
                        )}

                        {user.menu_usuarios &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuUsuarios"
                            >
                              USUARIOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            USUARIOS
                          </NavLink>
                        )}

                        {user.menu_logs &&
                        (AREAS_CAMARONES == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuLogs">
                              LOGS
                            </NavLink>
                          </NavItem>
                        ) : undefined}
                      </>
                    ) : (
                      <>
                        {user.menuTiendas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuTiendas">
                              TIENDAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            TIENDAS
                          </NavLink>
                        )}
                      </>
                    )}
                  </Nav>
                  <NavbarText>
                    <NavLink className="titulosNav">
                      <Dropdown
                        direction="left"
                        isOpen={dropdownOpen}
                        toggle={toggleLogout}
                      >
                        <DropdownToggle color="link">
                          <i class="fas fa-user-cog fa-2x"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem href="/logout">Logout</DropdownItem>
                          <DropdownItem onClick={togglePassword}>
                            Password
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </NavLink>
                  </NavbarText>
                  <Modal
                    size="sm"
                    isOpen={modalPassword}
                    toggle={togglePassword}
                  >
                    <ModalHeader toggle={togglePassword}>
                      <h4>Cambio de contraseña</h4>
                    </ModalHeader>
                    <ModalBody>
                      <Password />
                    </ModalBody>
                  </Modal>
                </Collapse>
              </Navbar>
            </>
          ) : (
            <Navbar color="dark" dark expand="md">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <NavbarText>
                  <NavLink className="titulosNav" href="/logout">
                    <i class="fas fa-user-cog fa-2x"></i>
                  </NavLink>
                </NavbarText>
              </Collapse>
            </Navbar>
          )}
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
