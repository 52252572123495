import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function TraspasosDineroCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TRASPASOS_DINERO = process.env.REACT_APP_URL_TRASPASOS_DINERO;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_TOMATLAN = process.env.REACT_APP_AREAS_TOMATLAN;
  const AREAS_COCO = process.env.REACT_APP_AREAS_COCO;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;
  const AREAS_SN_ANTONIO = process.env.REACT_APP_AREAS_SN_ANTONIO;


  const BANCO_RODRIGO = process.env.REACT_APP_BANCO_RODRIGO;
  const BANCO_BANAMEX_SHALOMICO = process.env.REACT_APP_BANCO_BANAMEX_SHALOMICO;
  const BANCO_BANAMEX_TANDERA = process.env.REACT_APP_BANCO_BANAMEX_TANDERA;
  const BANCO_BBVA_COCO_STYLE = process.env.REACT_APP_BANCO_BBVA_COCO_STYLE;
  const BANCO_EFECTIVO_TOMATLAN = process.env.REACT_APP_BANCO_EFECTIVO_TOMATLAN;
  const BANCO_EFECTIVO_SN_ANTONIO =
    process.env.REACT_APP_BANCO_EFECTIVO_SN_ANTONIO;
  const BANCO_EFECTIVO_COCO = process.env.REACT_APP_BANCO_EFECTIVO_COCO;
  const BANCO_EFECTIVO_PLAZA = process.env.REACT_APP_BANCO_EFECTIVO_PLAZA;
  const BANCO_TPV_MIFEL = process.env.REACT_APP_BANCO_TPV_MIFEL;
  const BANCO_MIFEL = process.env.REACT_APP_BANCO_MIFEL;

  const BANCO_TPV_TOMATLAN = process.env.REACT_APP_BANCO_TPV_TOMATLAN;
  const BANCO_TPV_SN_ANTONIO = process.env.REACT_APP_BANCO_TPV_SN_ANTONIO;
  const BANCO_TPV_COCO = process.env.REACT_APP_BANCO_TPV_COCO;
  const BANCO_TPV_PLAZA = process.env.REACT_APP_BANCO_TPV_PLAZA;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [importe, setImporte] = useState(0);
  const [entrada, setEntrada] = useState("No");

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [bancos, setBancos] = useState([]);
  const [bancoOrigen, setBancoOrigen] = useState("");
  const [bancoDestino, setBancoDestino] = useState("");
  const [saldoOrigen, setSaldoOrigen] = useState(0);

  useMemo(() => {
    setSelectedArea(user.areas)

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allBancos = res.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [user]);

  const saveTraspaso = (event) => {
    event.preventDefault();
    setValidaBoton(false);

        axios.post(
          URL_TRASPASOS_DINERO,
          {
            fecha,
            importe,
            entrada,
            areas: selectedArea,
            bancoOrigen,
            bancoDestino
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Traspaso Dinero",
            detalle: `Fecha: ${fecha} / Tienda: ${selectedArea} / Importe: ${importe} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  function jalaBancoOrigen(e){
    setBancoOrigen(e)
    bancos.map((a) =>{
      if(a._id == e){
        setSaldoOrigen(a.abonos - a.cargos)
      }
    })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastosTiendas ? (
        <div className="container">
          <div className="card container col-sm-6">
            <h3 align="center">Nuevo Traspaso de Dinero</h3>
            <Form onSubmit={saveTraspaso}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>

                <Col md={6}>
              <Label>Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas != AREAS_GENERAL) {
                          if(a._id == user.areas){
                          return <option value={a._id}>{a.name}</option>
                        }
                        }else{
                          if(a._id != AREAS_GENERAL){
                            return <option value={a._id}>{a.name}</option>
                          }
                        }
                      })}
              </Input>
            </Col>
          
                  <Col sm={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Importe
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Importe"
                      value={importe}
                      required
                      onChange={(e) =>{
                        if(e.target.value <= saldoOrigen){
                        setImporte(e.target.value)
                        }else{
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "El importe excede el saldo del banco",
                          })
                        }
                        }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>Banco Origen</Label>
                <Input
                              type="select"
                              value={bancoOrigen}
                              onChange={(e) => {
                                jalaBancoOrigen(e.target.value);
                              }}
                            >
                              <option value="">Selecciona un Banco</option>
                              {bancos
                                .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                                .map((a) => {
                                  if (
                                    (user.areas == AREAS_COCO &&
                                      a._id == BANCO_EFECTIVO_COCO) ||
                                    (user.areas == AREAS_TOMATLAN &&
                                      a._id == BANCO_EFECTIVO_TOMATLAN) ||
                                    (user.areas == AREAS_PLAZA &&
                                      a._id == BANCO_EFECTIVO_PLAZA) ||
                                    (user.areas == AREAS_SN_ANTONIO &&
                                      a._id == BANCO_EFECTIVO_SN_ANTONIO) ||
                                    (user.areas == AREAS_COCO &&
                                      a._id == BANCO_TPV_COCO) ||
                                    (user.areas == AREAS_TOMATLAN &&
                                      a._id == BANCO_TPV_TOMATLAN) ||
                                    (user.areas == AREAS_PLAZA &&
                                      a._id == BANCO_TPV_PLAZA) ||
                                    (user.areas == AREAS_SN_ANTONIO &&
                                      a._id == BANCO_TPV_SN_ANTONIO)  ||
                                      a._id == BANCO_TPV_MIFEL||
                                      a._id == BANCO_MIFEL
                                     
                                  ) {
                                    return (
                                      <option value={a._id}>
                                        {a.banco} {a.cuenta}
                                      </option>
                                    );
                                  }else if (user.areas == AREAS_GENERAL){
                                    return (
                                      <option value={a._id}>
                                        {a.banco} {a.cuenta}
                                      </option>
                                    );
                                  }
                                })}
                            </Input>
                            </Col>
                            <Col md={6}>
                    <Label>Banco Destino</Label>
                <Input
                              type="select"
                              value={bancoDestino}
                              onChange={(e) => {
                                setBancoDestino(e.target.value);
                              }}
                            >
                              <option value="">Selecciona un Banco</option>
                              {bancos
                                .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                                .map((a) => {
                                  if (
                                    a._id == BANCO_RODRIGO
                                  ) {
                                    return (
                                      <option value={a._id}>
                                        {a.banco} {a.cuenta}
                                      </option>
                                    );
                                  }
                                })}
                            </Input>
                            </Col>
                </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/MenuTiendas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosDineroCreate;
