import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import ColaboradoresInfo from "./ColaboradoresInfo";
import PasswordColaboradores from "./PasswordColaboradores";

function TablaColaboradores(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLABORADORES_EDIT = `${process.env.REACT_APP_URL_COLABORADORES}/${props._id}`;
  const URL_PUESTOS = process.env.REACT_APP_URL_PUESTOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_USERS_COL = `${process.env.REACT_APP_URL_USERS}/email/${props.email}`;
  const URL_BAJA = `${process.env.REACT_APP_URL_COLABORADORES}/${props._id}`;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [usuarios, setUsuarios] = useState([]);
  const [text, setText] = useState(false);
  const [colaboradores, setColaboradores] = useState("");
  const [nombre, setNombre] = useState(props.nombre);
  const [apellido, setApellido] = useState(props.apellido);
  const [identificacion, setIdentificacion] = useState(props.identificacion);
  const [direccion, setDireccion] = useState(props.direccion);
  const [fecha_ingreso, setFechaIngreso] = useState(props.fecha_ingreso);
  const [fecha_nacimiento, setFechaNacimiento] = useState(
    props.fecha_nacimiento
  );
  const [telefono, setTelefono] = useState(props.telefono);
  const [contacto_emergencia, setContEmer] = useState(
    props.contacto_emergencia
  );
  const [telefono_emergencia, setTelEmer] = useState(props.telefono_emergencia);
  const [sueldo_actual, setSueldoActual] = useState(props.sueldo_actual);
  const [sueldo_anterior, setSueldoAnterior] = useState(props.sueldo_anterior);
  const [nuevo_sueldo, setNuevoSueldo] = useState();
  const [observaciones, setObservaciones] = useState(props.observaciones);
  const [email, setEmail] = useState(props.email);
  const [puestos, setPuestos] = useState([]);
  const [selectedPuesto, setSelectedPuesto] = useState(props.idPuesto);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(props.idPlantel);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(props.idArea);
  const [banco, setBanco] = useState(props.banco);
  const [frecuencia, setFrecuencia] = useState(props.frecuencia);
  const [clabe, setClabe] = useState(props.clabe);
  const [cobrador, setCobrador] = useState(props.cobrador);
  const [acceso_sistema, setAccesoSistema] = useState(props.acceso_sistema);
  const [fecha_aumento, setFechaAumento] = useState("");
  const [idUser, setIdUser] = useState('');
  const [vendedor, setVendedor] = useState(props.vendedor);



  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalAumento, setModalAumento] = useState(false);
  const toggleAumento = () => setModalAumento(!modalAumento);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);

  let idColaborador = props._id;

  useEffect(() => {
    axios
      .get(URL_COLABORADORES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPuestos = res.data;
        setPuestos(allPuestos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_USERS_COL, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        setUsuarios(allUsuarios);
        let id = response.data[0]._id
        setIdUser(id)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

let menu_cobradores

 function editColaborador(event) {
    if (cobrador=="Si"){
      menu_cobradores = true
    } else {
      menu_cobradores = false
    }
    console.log(cobrador)
    const URL_SIGNUP_COL = `${process.env.REACT_APP_URL_USERS}/${idUser}`;
    event.preventDefault();
    axios
    .all([
    axios
      .patch(
        URL_COLABORADORES_EDIT,
        {
          nombre,
          apellido,
          identificacion,
          direccion,
          email,
          fecha_ingreso,
          fecha_nacimiento,
          telefono,
          contacto_emergencia,
          telefono_emergencia,
          observaciones,
          puestos: selectedPuesto,
          banco,
          clabe,
          cobrador,
          planteles: selectedPlantel,
          areas: selectedArea,
          acceso_sistema,
          vendedor
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      ),
      axios.patch(
        URL_SIGNUP_COL,
         {
           nombre,
           apellido,
           email,
           planteles: selectedPlantel,
           areas: selectedArea,
           is_active: acceso_sistema,
           menu_cobradores,
           vendedor
         },
         {
           headers: {
             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
           },
         }
       ),
      ])
      .then(() => {   
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Edito Colaborador',
            detalle: `Nombre: ${nombre} / Apellido: ${apellido}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function aumentoColaborador(event) {
    event.preventDefault();
    axios
      .patch(
        URL_COLABORADORES_EDIT,
        {
          sueldo_actual: nuevo_sueldo,
          sueldo_anterior: sueldo_actual,
          ultimo_aumento: fecha_aumento,
          frecuencia
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleAumento();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function baja() {
    const URL_SIGNUP_COL = `${process.env.REACT_APP_URL_USERS}/${idUser}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de baja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        axios.all([
        axios
          .patch(
            URL_BAJA,
            {
              is_active: "No",
              acceso_sistema: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
          axios.patch(
            URL_SIGNUP_COL,
             {
               is_active: "No"
             },
             {
               headers: {
                 Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
               },
             }
           ),
          ])
          .then(() => {
            toggle();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            window.location.reload();
          })
           .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }})}

  function alta() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de Alta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Alta!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_BAJA,
            {
              is_active: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio de Alta",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <td>{props.is_active}</td>
      <td>{props.nombre} {props.apellido} </td>
      <td>{props.jefe} {props.jefeApellido} </td>
      <td>{props.telefono}</td>
      <td>{props.puesto}</td>
      <td>{props.area}</td>
      <td>
        <span>
          <Button color="success" size="sm" onClick={toggle} id="Detalle">
            <i class="fas fa-file-alt"></i>
          </Button>
        </span>
        <Modal size="sm" isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <h4>Informacion del Empleado</h4>
          </ModalHeader>
          <ModalBody>
            <ColaboradoresInfo idColaborador={idColaborador} />
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
        {user.empleados_create ? (
          <Button color="info" id="Editar" size="sm" onClick={toggleEdit}>
            <i class="far fa-edit"></i>
          </Button>
        ) : (
          <Button color="info" id="Editar" size="sm" disabled>
            <i class="far fa-edit"></i>
          </Button>
        )}
        {user.empleados_create ?(
        <Button color="success" id="Password" size="sm" onClick={togglePassword}>
          <i class="fas fa-user-lock"></i>
        </Button>
        ):(
          <Button color="success" id="Password" size="sm" disabled>
          <i class="fas fa-user-lock"></i>
        </Button>
        )}

      <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <PasswordColaboradores idColaborador={idUser}/>
                  </ModalBody>
                </Modal>
        {user.empleados_sueldo ? (
          <Button
            color="primary"
            id="Aumento"
            size="sm"
            onClick={toggleAumento}
          >
            <i class="fas fa-dollar-sign"></i>
          </Button>
        ) : (
          <Button color="primary" id="Aumento" size="sm" disabled>
            <i class="fas fa-dollar-sign"></i>
          </Button>
        )}
        {user.empleados_create ? (
          props.is_active == "Si" ? (
            <span>
              <Button
                color="danger"
                id="Baja"
                onClick={baja}
                size="sm"
              >
                <i class="far fa-times-circle"></i>
              </Button>
            </span>
          ) : (
            <span>
              <Button color="primary"  onClick={alta} size="sm">
                <i class="far fa-check-circle"></i>
              </Button>
            </span>
          )
        ) : undefined}
      </td>
      <Modal size="sm" isOpen={modalAumento} toggle={toggleAumento}>
        <ModalHeader toggle={toggleAumento}>
          <h4>Editar Sueldo de {props.nombre} {props.apellido} </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>
              Sueldo Anterior{" "}
              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                  props.sueldo_anterior
                )}{" "}
            </Label>
          </Row>
          <Row>
            <Label>Ultimo Aumento {props.ultimo_aumento}</Label>
          </Row>
          <Row>
            <Label className="rojoBold">
              Sueldo Actual{" "}
              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(props.sueldo_actual)}{" "}
            </Label>
          </Row>

          <Row>
          <Label>Frecuencia</Label>
                <Input
                    type="select"
                    placeholder="Frecuencia"
                    value={frecuencia}
                    required
                    onChange={(e) => {
                      setFrecuencia(e.target.value);
                    }}
                  >
                    <option value="0">Frecuencia</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Mensual">Mensual</option>
                  </Input>
            <Label>Nuevo Sueldo</Label>
            <Input
              type="text"
              placeholder="Nuevo Sueldo"
              value={nuevo_sueldo}
              required
              onChange={(e) => {
                setNuevoSueldo(e.target.value);
              }}
            />

            <Label>Fecha de Aumento</Label>
            <Input
              type="date"
              value={fecha_aumento}
              required
              onChange={(e) => {
                setFechaAumento(e.target.value);
              }}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={aumentoColaborador}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Informacion de {props.nombre} {props.apellido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Apellido</Label>
              <Input
                type="text"
                placeholder="Apellido"
                value={apellido}
                required
                onChange={(e) => {
                  setApellido(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Email</Label>
              <Input
                type="text"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label>Direccion</Label>
              <Input
                type="text"
                placeholder="Direccion completa"
                value={direccion}
                required
                onChange={(e) => {
                  setDireccion(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Puesto</Label>
              <Input
                type="select"
                value={selectedPuesto}
                required
                onChange={(e) => {
                  setSelectedPuesto(e.target.value);
                }}
              >
                <option value="0">Selecciona un Puesto</option>
                {puestos.map((a) => {
                  return <option value={a._id}>{a.name}</option>;
                })}
              </Input>
            </Col>
            <Col md={4}>
              <Label>Identificacion</Label>
              <Input
                type="text"
                placeholder="No. Identificacion oficial"
                value={identificacion}
                required
                onChange={(e) => {
                  setIdentificacion(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label>Fecha de Nacimiento</Label>
              <Input
                type="date"
                value={fecha_nacimiento}
                required
                onChange={(e) => {
                  setFechaNacimiento(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Fecha de Ingreso</Label>
              <Input
                type="date"
                value={fecha_ingreso}
                required
                onChange={(e) => {
                  setFechaIngreso(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Telefono</Label>
              <Input
                type="text"
                placeholder="Numero de Telefono"
                value={telefono}
                required
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Banco</Label>
              <Input
                type="text"
                placeholder="Banco"
                value={banco}
                required
                onChange={(e) => {
                  setBanco(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Clabe</Label>
              <Input
                type="number"
min="0"
step="any"
                placeholder="Clabe"
                value={clabe}
                required
                onChange={(e) => {
                  setClabe(e.target.value);
                }}
              />
            </Col>
         
            <Col md={3}>
              <Label>Contacto Emergencia</Label>
              <Input
                type="text"
                placeholder="Nombre del contacto"
                value={contacto_emergencia}
                required
                onChange={(e) => {
                  setContEmer(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Telefono Emergencia</Label>
              <Input
                type="text"
                placeholder="Telefono del contacto"
                value={telefono_emergencia}
                required
                onChange={(e) => {
                  setTelEmer(e.target.value);
                }}
              />
            </Col>
            </Row>
            <Row>
              <Col md={3}>
            <Label className="mr-sm-2">Area</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option>Selecciona</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
                </Col>
           
            <Col md={3}>
              {props.is_active == "Si" ? (
                <div>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Acceso al Sistema
                  </Label>
                  <Input
                    type="select"
                    value={acceso_sistema}
                    required
                    onChange={(e) => {
                      setAccesoSistema(e.target.value);
                    }}
                  >
                    <option value={props.acceso_sistema}>
                      {props.acceso_sistema}
                    </option>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </Input>
                </div>
              ) : (
                <div>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Acceso al Sistema
                  </Label>
                  <Input type="select" disabled>
                    <option>Usuario Inactivo</option>
                  </Input>
                </div>
              )}
            </Col>
            <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Vendedor
                </Label>
                <Input
                  type="select"
                  value={vendedor}
                  required
                  onChange={(e) => {
                    setVendedor(e.target.value);
                  }}
                >
                  <option value="0">Vendedor</option>
                  <option value="No">No</option>
                  <option value="Si">Si</option>
                </Input>
              </Col>
            
            <Col md={3}>
              <Label for="exampleText">Observaciones</Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editColaborador}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaColaboradores;
