import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Table,
  Progress,
  Spinner,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoPresupuestos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PRESUPUESTOS = process.env.REACT_APP_URL_PRESUPUESTOS;
  const URL_PERIODOS_GASTOS = process.env.REACT_APP_URL_PERIODOS_GASTOS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const [presupuestos, setPresupuestos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [ciclos, setCiclos] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState("");
  const [terminado, setTerminado] = useState(2);
  const [autorizado, setAutorizado] = useState("");

  const [selectedDepartamentoEdit, setSelectedDepartamentoEdit] = useState("");
  const [selectedPeriodoEdit, setSelectedPeriodoEdit] = useState("");

  const [idPresupuesto, setIdPresupuesto] = useState("");
  const [sumar, setSumar] = useState(0);
  const [importe, setImporte] = useState(0);
  const [disponible, setDisponible] = useState(0);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_PRESUPUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPresupuestos = response.data;
        let arrayTabla = allPresupuestos
          .sort((a, b) =>
            a.periodosGastos[0].fecha_inicio < b.periodosGastos[0].fecha_inicio
              ? 1
              : -1
          )
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              autorizado: a.autorizado,
              importe: a.importe,
              utilizado: a.utilizado,
              disponible: a.disponible,
              departamento: a.departamentos[0].name,
              idDepartamento: a.departamentos[0]._id,
              periodoGasto: a.periodosGastos[0].name,
              idPeriodoGasto: a.periodosGastos[0]._id,
              ciclo: a.periodosGastos[0].ciclos[0].name,
              idCiclo: a.periodosGastos[0].ciclos[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setPresupuestos(allPresupuestos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PERIODOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPeriodos = res.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editPresupuesto() {
    const URL_PRESUPUESTOS_EDIT = `${process.env.REACT_APP_URL_PRESUPUESTOS}/${idPresupuesto}`;
    let nuevoImporte = parseFloat(importe) + parseFloat(sumar);
    let nuevoDisponible = parseFloat(disponible) + parseFloat(sumar);
    axios
      .patch(
        URL_PRESUPUESTOS_EDIT,
        {
          importe: nuevoImporte,
          disponible: nuevoDisponible,
          departamentos: selectedDepartamentoEdit,
          periodosGastos: selectedPeriodoEdit,
          autorizado: "No",
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Presupuesto",
            detalle: ` Periodo: ${selectedPeriodoEdit} / Departamento: ${selectedDepartamentoEdit}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autorizar(id) {
    const URL_PRESUPUESTOS_EDIT = `${process.env.REACT_APP_URL_PRESUPUESTOS}/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Autorizara!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_PRESUPUESTOS_EDIT,
            {
              autorizado: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Autorizo",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            console.log(error);
          });
      }
    });
  }

  // Autorizar varias
  function autorizarVarias() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Autorizara!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalPresupuesto = comments.length;
        comments.map((n) => {
          if (n.select == true) {
            const URL_PRESUPUESTOS_EDIT = `${process.env.REACT_APP_URL_PRESUPUESTOS}/${n._id}`;

            axios
              .patch(
                URL_PRESUPUESTOS_EDIT,
                {
                  autorizado: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )

              .then(() => {
                totalPresupuesto = totalPresupuesto - 1;
                if (totalPresupuesto == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Autorizar presupuestos general",
                      // detalle: `Fecha: ${fecha}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalPresupuesto = totalPresupuesto - 1;
          }
        });
      }
    });
  }

  // Fin autorizar varias

  function quitar(id) {
    const URL_PRESUPUESTOS_EDIT = `${process.env.REACT_APP_URL_PRESUPUESTOS}/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Quitara la Autorizacion!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Confirmo!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_PRESUPUESTOS_EDIT,
            {
              autorizado: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se quito la autorizacion",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            console.log(error);
          });
      }
    });
  }

  function jalaInfo(
    id,
    importePresu,
    departamentosId,
    departamentosName,
    periodosGastosId,
    periodosGastosName,
    disponible
  ) {
    setIdPresupuesto(id);
    setImporte(importePresu);
    setSelectedDepartamentoEdit(departamentosId);
    setSelectedPeriodoEdit(periodosGastosId);
    setDisponible(disponible);
    toggleEdit();
  }

  const headers = [
    { name: "Departamento", field: "departamento", sortable: true },
    { name: "Periodo", field: "periodoGasto", sortable: true },
    { name: "Ciclo", field: "ciclo", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Utilizado", field: "utilizado", sortable: false },
    { name: "Disponible", field: "disponible", sortable: false },
    // { name: "Terminado", field: "", sortable: false },
    { name: "Progreso", field: "", sortable: false },
    { name: "Autorizar", field: "", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.periodoGasto.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }

    if (selectedPeriodo) {
      computedComments = computedComments.filter((e) =>
        e.idPeriodoGasto.includes(selectedPeriodo)
      );
    }

    if (selectedCiclo) {
      computedComments = computedComments.filter((e) =>
        e.idCiclo.includes(selectedCiclo)
      );
    }

    if (autorizado) {
      computedComments = computedComments.filter((e) =>
        e.autorizado.includes(autorizado)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedPeriodo,
    selectedDepartamento,
    selectedCiclo,
    autorizado,
  ]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_presupuestos ? (
        <div className="row">
          {
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {user.presupuestos_create ? (
                    <Button
                      size="sm"
                      href="/PresupuestosCreate"
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Crear Presupuesto
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      disabled
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Crear Presupuesto
                    </Button>
                  )}
                  <Button
                    size="sm"
                    href="/MenuGastos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                  <h3 align="center">Presupuestos</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                    <div className="col-md-6 d-flex flex-row-reverse">
                      <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  </div>
                  <Row></Row>
                  <br />
                  <Table
                    size="sm"
                    striped
                    borderless
                    className="table-responsive-xl"
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tr>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedDepartamento}
                          required
                          onChange={(e) => {
                            setSelectedDepartamento(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {departamentos
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedPeriodo}
                          required
                          onChange={(e) => {
                            setSelectedPeriodo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {periodos
                            .sort((a, b) =>
                              a.fecha_inicio < b.fecha_inicio ? 1 : -1
                            )
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCiclo}
                          required
                          onChange={(e) => {
                            setSelectedCiclo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {ciclos
                            .sort((a, b) => (a.name < b.name ? 1 : -1))

                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={terminado}
                            required
                            onChange={(e) => {setTerminado(e.target.value)
                                              setCurrentPage(1);}}
                          >
                            <option value="">Todos</option>
                            <option value="0">Disponibles</option>
                          </Input>
                        </td> */}
                      <td style={{ paddingTop: "0px" }}>
                        {user.presupuestos_autorizar ? (
                          <div>
                            <Button
                              color="#0F3A5C"
                              size="sm"
                              onClick={autorizarVarias}
                            >
                              {" "}
                              Autorizar Varios
                            </Button>
                            <br />
                            <Input
                              id="espacioH1"
                              type="checkbox"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                setComments(
                                  commentsData.map((n) => {
                                    if (n.autorizado == "No") {
                                      n.select = checked;
                                    }
                                    return n;
                                  })
                                );
                              }}
                            />
                          </div>
                        ) : undefined}
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={autorizado}
                          required
                          onChange={(e) => {
                            setAutorizado(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value="">Selecciona</option>
                          <option value="No">Por Autorizar</option>
                          <option value="Si">Autorizados</option>
                        </Input>
                      </td>
                    </tr>
                    <tbody>
                      {commentsData.map((a) => {
                        let PU = parseInt((a.utilizado * 100) / a.importe);
                        let PD = parseInt((a.disponible * 100) / a.importe);
                        return (
                          <tr>
                            <td>{a.departamento}</td>
                            <td>{a.periodoGasto}</td>
                            <td>{a.ciclo}</td>
                            <td align="center">
                              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                  a.importe
                                )}
                            </td>
                            <td align="center">
                              {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                  a.utilizado
                                )}
                            </td>
                            <td align="center">
                              {a.disponible == 0
                                ? "-"
                                : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                    a.disponible
                                  )}
                            </td>
                            {/* <td>
                                {a.disponible == 0 ?(
                                  "Terminado"
                                ) : (
                                  "Disponible"
                                )
                                }
                              </td> */}
                            <td>
                              <Progress multi id="Util">
                                <Progress
                                  animated
                                  bar
                                  color="danger"
                                  value={PU}
                                >
                                  {PU}%
                                </Progress>
                                <Progress animated bar color="info" value={PD}>
                                  {PD}%
                                </Progress>
                              </Progress>
                            </td>
                            <td key={a._id}>
                              {a.autorizado == "No" ? (
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    setComments(
                                      comments.map((data) => {
                                        if (a._id == data._id) {
                                          data.select = checked;
                                        }
                                        return data;
                                      })
                                    );
                                  }}
                                  checked={a.select}
                                />
                              ) : undefined}
                            </td>
                            <td>
                              {user.presupuestos_autorizar ? (
                                a.autorizado == "No" ? (
                                  <span>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      id="Autorizar"
                                      onClick={(e) => autorizar(a._id)}
                                    >
                                      <i class="fas fa-check"></i>
                                    </Button>
                                  </span>
                                ) : (
                                  <span>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      id="Des"
                                      onClick={(e) => quitar(a._id)}
                                    >
                                      <i class="fas fa-times"></i>
                                    </Button>
                                  </span>
                                )
                              ) : a.autorizado == "No" ? (
                                <span>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    id="Autorizar"
                                    disabled
                                  >
                                    <i class="fas fa-check"></i>
                                  </Button>
                                </span>
                              ) : (
                                <span>
                                  <Button
                                    color="danger"
                                    size="sm"
                                    id="Des"
                                    disabled
                                  >
                                    <i class="fas fa-times"></i>
                                  </Button>
                                </span>
                              )}
                              {user.presupuestos_create ? (
                                <Button
                                  color="info"
                                  size="sm"
                                  id="Editar"
                                  onClick={(e) =>
                                    jalaInfo(
                                      a._id,
                                      a.importe,
                                      a.idDepartamento,
                                      a.departamento,
                                      a.idPeriodoGasto,
                                      a.periodoGasto,
                                      a.disponible
                                    )
                                  }
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="info"
                                  size="sm"
                                  id="Editar"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              )}
                              {/* {user.presupuestos_create ? (
                              <Baja
                                idStatus={a._id}
                                is_active={a.is_active}
                                URL_BAJA={process.env.REACT_APP_URL_PRESUPUESTOS}
                              />):undefined} */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      ) : // </div>
      undefined}
      <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Presupuesto</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">
            Importe Original{" "}
            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(importe)}
          </Label>
          <br />
          <Label className="mr-sm-2">Aumentar Presupuesto</Label>
          <Row>
            <Col md={6}>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Monto a agregar"
                value={sumar}
                required
                onChange={(e) => {
                  setSumar(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Periodo</Label>
              <Input
                type="select"
                value={selectedPeriodoEdit}
                required
                onChange={(e) => {
                  setSelectedPeriodoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona un Periodo</option>
                {periodos
                  // .sort((a, b) => a.name > b.name ? 1 : -1)
                  .map((c) => {
                    return (
                      <option value={c._id}>
                        {c.name} Ciclo {c.ciclos[0].name}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={6}>
              <Label className="mr-sm-2">Departamento</Label>
              <Input
                type="select"
                value={selectedDepartamentoEdit}
                required
                onChange={(e) => {
                  setSelectedDepartamentoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona un Departamento</option>
                {departamentos
                  // .sort((a, b) => a.name > b.name ? 1 : -1)
                  .map((d) => {
                    return <option value={d._id}>{d.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPresupuesto}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPresupuestos;
