import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";

function ReporteFlujoEfectivo() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_ABONOS_PROVEEDORES = process.env.REACT_APP_URL_ABONOS_PROVEEDORES;
  const URL_GASTOS_GENERAL = process.env.REACT_APP_URL_GASTOS_GENERAL;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [abonos, setAbonos] = useState([]);
  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [abonosProveedores, setAbonosProveedores] = useState([]);
  const [gastosGenerales, setGastosGenerales] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [fecha, setFecha] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_ABONOS}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allAbonos = response.data;
        let arrayAbonos = allAbonos
          .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
          .map((a) => {
            if (a.is_active == "Si" && a.formaPago == "Efectivo" && a.notasCargo != "") {
              return {
                    movimiento: "Abono Nota Cargo",
                    numero: a.idAbono,
                    clienteProv: a.clientes[0].nombre_comercial,
                    idClienteProv: a.clientes[0]._id,
                    fecha: a.fecha,
                    area: a.notasCargo[0].clientes[0].areas[0].name,
                    idArea: a.notasCargo[0].clientes[0].areas[0]._id,
                    importe: a.importe
              };
            }else if(a.is_active == "Si" && a.formaPago == "Efectivo" && a.surtidos != ""){
              return {
                movimiento: "Abono Surtido",
                numero: a.idAbono,
                clienteProv: a.clientes[0].nombre_comercial,
                idClienteProv: a.clientes[0]._id,
                fecha: a.fecha,
                area: a.surtidos[0].areas[0].name,
                idArea: a.surtidos[0].areas[0]._id,
                importe: a.importe
          };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataAbonos = Object.values(arrayAbonos);
        setAbonos(dataAbonos);

        axios
      .get(
        `${URL_ABONOS_TIENDAS}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allAbonosTiendas = response.data;
        let arrayAbonosTiendas = allAbonosTiendas
          .sort((a, b) => (a.numero > b.numero ? 1 : -1))
          .map((a) => {
            if (a.is_active == "Si") {
              return {
                    movimiento: "Abono Tienda",
                    numero: a.idAbonoTiendas,
                    clienteProv: a.clientes[0].nombre_comercial,
                    idClienteProv: a.clientes[0]._id,
                    fecha: a.fecha,
                    area: a.areas[0].name,
                    idArea: a.areas[0]._id,
                    importe: a.efectivo
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataAbonosTiendas = Object.values(arrayAbonosTiendas);
        setAbonosTiendas(dataAbonosTiendas);


        axios
      .get(
        `${URL_GASTOS_GENERAL}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allGastosGenerales = response.data;
        let arrayGastosGenerales = allGastosGenerales
          .sort((a, b) => (a.numero > b.numero ? 1 : -1))
          .map((a) => {
            if (a.is_active == "Si" && a.formaPago == "Efectivo") {
              return {
                    movimiento: "Gasto",
                    numero: a.idGastoGeneral,
                    clienteProv: a.conceptosGastos[0].name,
                    idClienteProv: a.conceptosGastos[0]._id,
                    fecha: a.fecha,
                    area: a.areas[0].name,
                    idArea: a.areas[0]._id,
                    importe: - a.total
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataGastosGenerales = Object.values(arrayGastosGenerales);
        setGastosGenerales(dataGastosGenerales);

        let agrupado = dataAbonos.concat(dataAbonosTiendas, dataGastosGenerales)
        setComments(agrupado)

      })
      .catch((err) => {
        console.log(err);
      });

      })
      .catch((err) => {
        console.log(err);
      });


      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    let TotalTabla = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        TotalTabla = TotalTabla + a.importe;
        return [
          a.fecha,
          a.clienteProv,
          a.movimiento,
          a.numero,
          a.area,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);

    doc.setFontSize(14);
    doc.text(`Reporte Utilidades`, 15, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 15, 35);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Cliente / Prov",
          "Movimiento",
          "Numero",
          "Almacen",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTabla),
        ],
      ],
      showFoot: "lastPage",
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`Ventas.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          ClienteProv: a.clienteProv,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Almacen: a.area,
          Importe: a.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "FlujoEfectivo";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "FlujoEfectivo",
        sheetFilter: [
          "Fecha",
          "ClienteProv",
          "Movimiento",
          "Numero",
          "Almacen",
          "Importe",
        ],
        sheetHeader: [
          "Fecha",
          "ClienteProv",
          "Movimiento",
          "Numero",
          "Almacen",
          "Importe",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalTabla = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        TotalTabla = TotalTabla + a.importe;
        return [
          a.fecha,
          a.clienteProv,
          a.movimiento,
          a.numero,
          a.area,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);

    doc.setFontSize(14);
    doc.text(`Reporte Utilidades`, 15, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 15, 35);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Cliente / Prov",
          "Movimiento",
          "Numero",
          "Area",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTabla),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Reporte Utilidades",
          email: mailTo,
          fileName: "FlujoEfectivo.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Flujo de Efectivo.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente / Prov", field: "clienteProv", sortable: true },
    { name: "Movimietno", field: "movimiento", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.clienteProv.toLowerCase().includes(search.toLowerCase()) ||
          comment.movimiento.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe.toString().includes(search) ||
          comment.numero.toString().includes(search) 
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && 
      sorting.field != "importe" &&
      sorting.field != "numero"
    ) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computedComments = computedComments.sort(
      (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
  }

  if (
    sorting.field &&
    sorting.order === "asc" &&
    (sorting.field == "importe" ||
    sorting.field == "numero"
    )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  if (
    sorting.field &&
    sorting.order === "desc" &&
    (sorting.field == "importe" ||
    sorting.field == "numero"
    )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  let totalImporte = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Flujo Efectivo</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte Flujo Efectivo</h3>
          <div className="row">
          <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-12 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData
              .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
              .map((a) => {
                totalImporte = totalImporte + a.importe
                return (
                  <tr>
                    <td>{a.fecha}</td>
                    <td>{a.clienteProv}</td>
                    <td>{a.movimiento}</td>
                    <td>{a.numero}</td>
                    <td>{a.area}</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.importe)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="right">TOTAL</td>
                <td className="negrita">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImporte)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ReporteFlujoEfectivo;
