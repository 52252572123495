import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";

function ReporteFlujoEfectivo2() {
  const { user } = useContext(AuthContext);
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_GASTOS_GENERAL = process.env.REACT_APP_URL_GASTOS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [abonos, setAbonos] = useState([]);
  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [gastos, setGastos] = useState([]);
  const [TEF, setTEF] = useState(0);
  const [TTrans, setTTrans] = useState(0);
  const [TTDC, setTTDC] = useState(0);
  const [TEG, setTEG] = useState(0);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {


    axios
      .get(
        `${URL_ABONOS}FechasActivas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allAbonos = response.data;
        let arrayAbonos = allAbonos
          .map((a) => {
            // if(a.formaPago =="Efectivo"){
              return {
                    movimiento: "Abono",
                    formaPago: a.formaPago,
                    fecha: a.fecha,
                    importe: a.importe
              }
              // }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataAbonos = Object.values(arrayAbonos);

        let agrupadoAbonos = dataAbonos.reduce(function (groups, item) {
          const val = item["formaPago"];
          groups[val] = groups[val] || {
            formaPago: item.formaPago,
            importe: 0,
          };
          groups[val].importe += item.importe;
          groups[val].formaPago = item.formaPago;
          groups[val].movimiento = item.movimiento;
          return groups;
        }, []);

        let dataAbonos2 = Object.values(agrupadoAbonos);

        let efe = dataAbonos2.filter((e) => e.formaPago == "Efectivo");
        let TEfe = efe.map((c) => parseFloat(c.importe));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTEF(TE)

        let trans = dataAbonos2.filter((e) => e.formaPago == "Transfer");
        let TTrans = trans.map((c) => parseFloat(c.importe));
        let TT = TTrans.reduce((t, total, index) => t + total, 0);
        setTTrans(TT)

        let tdc = dataAbonos2.filter((e) => e.formaPago == "TDC");
        let TTdc = tdc.map((c) => parseFloat(c.importe));
        let TotTDC = TTdc.reduce((t, total, index) => t + total, 0);
        setTTDC(TotTDC)

        setAbonos(dataAbonos2);

      })


      axios
      .get(
        `${URL_ABONOS_TIENDAS}AbonosVentasDiarioGeneralActivas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allAbonosTiendas = response.data;
        let arrayAbonosTiendas = allAbonosTiendas
          .map((a) => {
              return {
                    movimiento: "Abono Tienda",
                    fecha: a.fecha,
                    is_active: a.is_active,
                    importe: a.total,
                    efectivo: a.efectivo,
                    tdc: a.tdc,
                    transfer: a.transfer,
                    cortesia: a.cortesia,
                    areas: a.areas[0]._id,
                    areasName: a.areas[0].name,
              }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataAbonosTiendas = Object.values(arrayAbonosTiendas);

        let agrupadoAbonosTiendas = dataAbonosTiendas.reduce(function (groups, item) {
          const val = item["areas"];
          groups[val] = groups[val] || {
            areas: item.areas,
            efectivo: 0,
            tdc: 0,
            transfer: 0,
            cortesia: 0,
          };
          groups[val].efectivo += item.efectivo;
          groups[val].tdc += item.tdc;
          groups[val].transfer += item.transfer;
          groups[val].cortesia += item.cortesia;
          groups[val].areas = item.areas;
          groups[val].areasName = item.areasName;
          return groups;
        }, []);

        let dataAbonosTiendas2 = Object.values(agrupadoAbonosTiendas);

        setAbonosTiendas(dataAbonosTiendas2);

      })



      axios
      .get(
        `${URL_GASTOS_GENERAL}GastosDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allGastos = response.data;
        let arrayGastos = allGastos
          .map((a) => {
            if(a.formaPago != "Transfer"){
              return {
                    movimiento: "Gasto",
                    formaPago: a.formaPago,
                    importe: a.total,
              }
              }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataGastos = Object.values(arrayGastos);

        let agrupadoGastos = dataGastos.reduce(function (groups, item) {
          const val = item["formaPago"];
          groups[val] = groups[val] || {
            formaPago: item.formaPago,
            importe: 0,
          };
          groups[val].importe += item.importe;
          groups[val].formaPago = item.formaPago;
          return groups;
        }, []);

        let dataGastos2 = Object.values(agrupadoGastos);

        let activos = dataGastos2.filter((e) => e.formaPago == "Efectivo");
        let TEfe = activos.map((c) => parseFloat(c.importe));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTEG(TE)

        setGastos(dataGastos2);

      })

      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);


  let totalTabla = 0
  let totalATEfectivo = 0
  let totalATTDC = 0
  let totalATTransfer = 0
  let totalATCortesia = 0
  let totalTablaGastos = 0
  let totalEfe = 0
  let totalVenta = 0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-6">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            
          </Row>
          
          <h3 align="center">Flujo Efectivo 2</h3>
          <div className="row">
            
            <div className="col-md-12 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />



          <h3>Ventas Tiendas</h3>
          <Table size="sm" striped borderless className="table-responsive-xl">
            {/* <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            /> */}
            <tbody>
              <tr>
                <th>Tienda</th>
                <th>Efectivo</th>
                <th>TDC</th>
                <th>Transfer</th>
                <th>Cortesia</th>
                <th>Total</th>
              </tr>
              {abonosTiendas
              .sort((a, b) => (a.areasName > b.areasName ? 1 : -1))
              .map((a) => {
                totalATEfectivo = totalATEfectivo + a.efectivo
                totalATTDC = totalATTDC + a.tdc
                totalATTransfer = totalATTransfer + a.transfer
                totalATCortesia = totalATCortesia + a.cortesia
                return (
                  <tr>
                    <td>{a.areasName}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo + a.tdc  + a.transfer + a.cortesia)}</td>
                  </tr>
                );
              })}

<tr>
                <td>Ventas Oficina</td>
                <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TEF)}</td>
                <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TTDC)}</td>
                <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TTrans)}</td>
                <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(0)}</td>
                <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TTDC + TTrans + TEF)}</td>
              </tr>
              <tr>
              <td className="negrita" align="right">Total</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATEfectivo + TEF)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATTDC + TTDC)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATTransfer + TTrans)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATCortesia)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATEfectivo + totalATTDC + totalATTransfer + totalATCortesia + TEF + TTDC + TTrans)}</td>
                
              </tr>
              
            </tbody>
          </Table>

          <br />

          <h3>Gastos</h3>
          <Table size="sm" striped borderless className="table-responsive-xl">
            {/* <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            /> */}
            <tbody>
              <tr>
                <th>Forma de Pago</th>
                <th>Importe</th>
              </tr>
              {gastos.map((a) => {
                totalTablaGastos = totalTablaGastos + a.importe
                return (
                  <tr>
                    <td>{a.formaPago}</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.importe)}
                    </td>
                  </tr>
                );
              })}
              <tr>
              <td className="negrita" align="right">Total</td>
              <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTablaGastos)}
                </td>
              </tr>
            </tbody>
          </Table>


          <br />

          <h3>Totales</h3>
          <Table size="sm" striped borderless className="table-responsive-xl">
            
            <tbody>
              
                  <tr>
                    <td className="negrita">Ingresos Efectivo</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(TEF + totalATEfectivo)}
                    </td>
                  </tr>
              
                  <tr>
                    <td className="negrita">Gastos Efectivo</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(TEG)}
                    </td>
                  </tr>

                  <tr>
                    <td className="negrita">Total</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      // }).format(totalTabla + totalATEfectivo - totalTablaGastos)}
                    }).format(TEF + totalATEfectivo - TEG)}
                    </td>
                  </tr>
            </tbody>
          </Table>
          
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ReporteFlujoEfectivo2;
