import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Table,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";


function Balance() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  const [info, setInfo] = useState([]);

  
  useEffect(() => {
    axios
      .get(`${URL_DASHBOARDS}Balance`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;
        setInfo(allInfo)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-sm-6">
          <h3 align="center">Balance</h3>

{info.length > 0 ? (
            <>
            <Table>
                  <tr>
                    <th>Valor Inventario</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].ValorInventario)}</td>
                  </tr>
                  <tr>
                    <th>Cuentas Por Cobrar</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].SaldoClientes)}</td>
                  </tr>
                  <tr>
                    <th>Saldo Bancos Efectivo</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].SaldoBancosEfectivo)}</td>
                  </tr>
                  <tr>
                    <th>Saldo Bancos Fiscal</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].SaldoBancosFiscal)}</td>
                  </tr>
                  <tr>
                    <th>Cuentas Por Pagar</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].SaldoProveedores)}</td>
                  </tr>
                  <tr>
                    <th>Balance General</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].ValorInventario + info[0].SaldoClientes + info[0].SaldoBancosEfectivo + info[0].SaldoBancosFiscal - info[0].SaldoProveedores)}</td>
                  </tr>
                </Table>
              </>
):undefined}
         
          
        </div>
      ) : undefined}
      
    </>
  );
}

export default Balance;
