import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../Baja";
import { TableHeader, Pagination, Search } from "../../DataTable";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";

function ReporteUltimoPrecioCliente() {
  const { user } = useContext(AuthContext);
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [clientes, setClientes] = useState([]);
  const [value, setValue] = useState("");

  const [existenMovs, setExistenMovs] = useState(true);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
if(value) {
  
    axios
    .get(`${URL_SEGUIMIENTOS}UltimoPrecioCliente/${value._id}/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allMovs = response.data;
      setComments(allMovs);
      setModalProgreso(false);
      if(allMovs.length == 0){
        setExistenMovs(false)
      } else{
        setExistenMovs(true)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
    
  },[value, selectedFechaInicio,selectedFechaFin, comments]);

  // function jalaCliente(selectedCliente) {
  //   setValue(selectedCliente)
  //   setComments([]);

  //   // Jala Movimientos
  //   axios
  //     .get(`${URL_SEGUIMIENTOS}UltimoPrecioCliente/${selectedCliente._id}/${selectedFechaInicio}/${selectedFechaFin}`, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       let allMovs = response.data;
  //       setComments(allMovs);
  //       if(allMovs.length == 0){
  //         setExistenMovs(false)
  //       } else{
  //         setExistenMovs(true)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }



  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Articulo", field: "nombreArticulo", sortable: true },
    { name: "Movimiento", field: "tipoMov", sortable: true },
    { name: "Numero", field: "numMov", sortable: false },
    { name: "Cliente", field: "destino", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: false },
    { name: "Precio", field: "precio", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.destino.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombreArticulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.tipoMov.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  return (
    <>
      <Header />
      <br />
      <br />
      {user.ventasTiendas ? (
        <div className="card col-12">
         <h3 align="center">Ultimo Precio Global</h3>
          <Row>
            <Col md={2}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setModalProgreso(true);
                        setValue(selectedCliente)
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
            </Col>
<Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setModalProgreso(true);
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setModalProgreso(true);
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>

           

            <div className="col-md-6 d-flex flex-row-reverse" id="logoutBoton">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </Row>
          <br />

          {/* Tabla Movimientos */}
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />

            <tbody>
              { existenMovs ? (
              commentsData.map((a) => {
                return (
                  <tr>
                    <td>{a.fecha}</td>
                    <td>{a.nombreArticulo}</td>
                    <td>{a.tipoMov}</td>
                    <td>{a.numMov}</td>
                    <td>{a.destino}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio)}</td>
                  </tr>
                );
              })): (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>No se encontraron Movimientos...</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                )}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

<Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ReporteUltimoPrecioCliente;
