import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const AREAS_CAMARONES = process.env.REACT_APP_AREAS_CAMARONES;
  const AREAS_PICAL = process.env.REACT_APP_AREAS_PICAL;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_costo, setTotalCosto] = useState(0);

  const [articulos, setArticulos] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      precio:0,
      costo:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    event.preventDefault();
    if (fecha != "" && observaciones != "")  {
      setValidaBoton(false);
      toggleProgreso();

      let totalArticulos =
        inputFields.length + inputFields.length
      try {
        await axios
          .post(
            `${URL_COMPRAS}Directa`,
            {
              fecha,
              observaciones,
              total_cantidad,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {

            axios
            .post(
              `${URL_NOTAS_CXP}`,
              {
                proveedores: selectedProveedor,
                compras: data.data._id,
                total_cantidad,
                total_costo: parseFloat(total_costo).toFixed(2),
                saldo: parseFloat(total_costo).toFixed(2),
                calidad:"No",
                fecha
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )



            inputFields.map((a) => {
              if (a.articulos != "" && a.cantidad != 0) {
                axios
                  .patch(
                    `${URL_INVENTARIOS}CargaInicial`,
                    {
                      articulos: a.articulos,
                      cantidad: parseFloat(a.cantidad),
                      areas: selectedArea,
                      tamanoCaja: "0",
                      pesoCaja: 1,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalArticulos = totalArticulos - 1;
                    if (totalArticulos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `Compra Directa`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              } else {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `Compra Directa`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            // Termina primer map



            inputFields.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    fecha,
                    cantidad: parseFloat(w.cantidad),
                    compras: data.data._id,
                    articulos: w.articulos,
                    areas: selectedArea,
                    precio:w.precio
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `Compra Directa`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const BuscaArticulo = (id, event) => {

    articulos.map((a) => {
      if (a._id == event.target.value) {
        let articulos = a._id;
        let piezasPorCaja = a.piezasPorCaja;
        handleChangeInputArticulo(id, articulos, piezasPorCaja);
      }
    });
  };

  const handleChangeInputArticulo = (id, articulos, piezasPorCaja) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = articulos;
        i.piezasPorCaja = piezasPorCaja
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value)
        i.costo = parseFloat(event.target.value) * i.cantidad
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / i.piezasPorCaja
        i.costo = parseFloat(event.target.value) * i.precio
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCaja = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cantidad = parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i.costo = parseFloat(event.target.value) * parseFloat(i.piezasPorCaja) * i.precio;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };
 

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let costo = inputFields.map((c) => parseFloat(c.costo));
    let TCosto = costo.reduce((t, total, index) => t + total, 0);
    setTotalCosto(TCosto);


  }

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 1,
      cajas: 0,
      precio:0,
      costo:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let costo = values.map((c) => parseFloat(c.costo));
    let TCosto = costo.reduce((t, total, index) => t + total, 0);
    setTotalCosto(TCosto);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Entrada</h3>
          <Form onSubmit={saveCompraNueva}>
            <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Almacen</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un almacen</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a._id == AREAS_CAMARONES || a._id == AREAS_PICAL){
                          return <option value={a._id}>{a.name}</option>;
                        }})}
                </Input>
              </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Pzas por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) =>
                        BuscaArticulo(inputField.id, event)
                      }
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulos
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                               {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      value={inputField.precio}
                      onChange={(event) =>
                        handleChangeInputPrecio(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cajas.toFixed(2)}
                      onChange={(event) =>
                        handleChangeInputCaja(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="string"
                      value={inputField.piezasPorCaja}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>

                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
         <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasCreate;
