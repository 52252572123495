import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoAbonosTiendasCanceladas() {
  const { user } = useContext(AuthContext);
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedVentaActiva, setSelectedVentaActiva] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [totalTotalEfectivo, setTotalTotalEfectivo] = useState(0);
  const [totalTotalTDC, setTotalTotalTDC] = useState(0);
  const [totalTotalComisionTDC, setTotalTotalComisionTDC] = useState(0);
  const [totalTotalTransfer, setTotalTotalTransfer] = useState(0);
  const [totalTotalCortesia, setTotalTotalCortesia] = useState(0);
  const [totalTotalTotal, setTotalTotalTotal] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    if(user.areas){
    if (user.areas != AREAS_GENERAL) {
      axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiario/${user.areas}/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
            .map((a) => {
              if(a.ventasTiendas!="" && a.ventasTiendas[0].is_active == "No" && a.total > 0){
              return {
                _id: a._id,
                numero: a.idAbonoTiendas,
                fecha: a.fecha,
                colaboradores: a.ventasTiendas[0].user[0].nombre + " " + a.ventasTiendas[0].user[0].apellido,
                idColaboradores: a.ventasTiendas[0].user[0]._id,
                observaciones: a.ventasTiendas[0].observaciones,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                condPago: a.ventasTiendas[0].cond_pago,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                comisionTDC: a.comisionTDC,
                transfer: a.transfer,
                cortesia: a.cortesia,
                consecutivo: a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
                area:a.areas[0].name,
                idArea:a.areas[0]._id,
                is_active: a.is_active,
                saldoCliente: a.clientes[0].saldo,
                numeroVenta: a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
                ventaActiva: a.ventasTiendas[0].is_active
              }
            }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          setAbonosTiendas(data);
          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
            .map((a) => {
              if(a.ventasTiendas!="" && a.ventasTiendas[0].is_active == "No" && a.total > 0){
              return {
                _id: a._id,
                numero: a.idAbonoTiendas,
                fecha: a.fecha,
                colaboradores:
                  a.ventasTiendas[0].user[0].nombre +
                  " " +
                  a.ventasTiendas[0].user[0].apellido,
                idColaboradores: a.ventasTiendas[0].user[0]._id,
                observaciones: a.ventasTiendas[0].observaciones,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                condPago: a.ventasTiendas[0].cond_pago,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                comisionTDC: a.comisionTDC,
                transfer: a.transfer,
                cortesia: a.cortesia,
                consecutivo:
                  a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
                  area:a.areas[0].name,
                  idArea:a.areas[0]._id,
                  is_active: a.is_active,
                  saldoCliente: a.clientes[0].saldo,
                  numeroVenta: a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
                  ventaActiva: a.ventasTiendas[0].is_active
              }
            }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          setAbonosTiendas(data);
          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_AREAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allAreas = response.data;
          setAreas(allAreas);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  const ListaVentas = comments.sort((a, b) =>
    a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1
  );

  function PDFTabla() {
    let TotalEfe = 0;
    let TotalTDC = 0;
    let TotalComisionTDC = 0;
    let TotalTransfer = 0;
    let TotalCortesia = 0;
    let TotalTabla = 0;
    const data = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAreaFiltro == 0 || selectedAreaFiltro == a.idArea)
      ) {
        TotalEfe = TotalEfe + a.efectivo;
        TotalTDC = TotalTDC + a.tdc;
        TotalComisionTDC = TotalComisionTDC + a.comisionTDC;
        TotalTransfer = TotalTransfer + a.transfer;
        TotalCortesia = TotalCortesia + a.cortesia;
        TotalTabla = TotalTabla + a.total;
        return [
          a.numero,
          a.area,
          a.fecha,
          a.clientes,
          a.colaboradores,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.comisionTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Abonos Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Tienda",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "Comision TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfe),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalComisionTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ],
      ],
      showFoot: "lastPage",
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`AbonosTiendas.pdf`);
  }

  function excel() {
    const dataExcel = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAreaFiltro == 0 || selectedAreaFiltro == a.idArea)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Vendedor: a.colaboradores,
          Efectivo: a.efectivo,
          TDC: a.tdc,
          ComisionTDC: a.comisionTDC,
          Transfer: a.transfer,
          Cortesia: a.cortesia,
          Total: a.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonosTiendasCanceladas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonosTiendasCanceladas",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "ComisionTDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "ComisionTDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalEfe = 0;
    let TotalTDC = 0;
    let TotalComisionTDC = 0;
    let TotalTransfer = 0;
    let TotalCortesia = 0;
    let TotalTabla = 0;
    const data = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAreaFiltro == 0 || selectedAreaFiltro == a.idArea)
      ) {
        TotalEfe = TotalEfe + a.efectivo;
        TotalTDC = TotalTDC + a.tdc;
        TotalComisionTDC = TotalComisionTDC + a.comisionTDC;
        TotalTransfer = TotalTransfer + a.transfer;
        TotalCortesia = TotalCortesia + a.cortesia;
        TotalTabla = TotalTabla + a.total;
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.colaboradores,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.comisionTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Abonos Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "comision TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfe),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalComisionTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ],
      ],
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`AbonosTiendas.pdf`);
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ventas",
          email: mailTo,
          fileName: "ListadoVentas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(fecha, clientes, total, numero, consecutivo, area, efectivo, tdc, comisionTDC, transfer, cortesia, saldoCliente) {
    const doc = new jsPDF("p", "cm", [8,10]);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 5,1,2,2);
    doc.setFontSize(6);
    doc.text(`Tandera SA de CV / RFC: TAN090216V4A`, .3, 1);
    doc.text(`Mixcalco 32`, .3, 1.3);
    doc.text(`Colonia: Centro CDMX`, .3, 1.6);
    doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, .3, 1.9);
    doc.text(`Fecha: ${fecha}`, .3, 2.2);
    doc.text(`Abono # ${numero} / Almacen: ${area}`, .3, 2.5);
    doc.text(`Cliente: ${clientes}`, .3, 2.8);
    doc.text(`Nota: ${consecutivo}`, .3, 3.1);
    doc.text(`Saldo: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoCliente)}`, .3, 3.4);
    doc.setFontSize(10);
    doc.text(`---ABONO---`, 2.5, 3.8);
    doc.autoTable({
      head: [["Efectivo", "TDC", "Comision TDC", "Transfer", "Cortesia", "Total"]],
      body: [
        [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
        ],
      ],
      styles: {
        fontSize: 8,
    },
      startY: 4.2,
      theme:"plain",
      margin:{left:0.3, right:0.7},
    });

    window.open(doc.output("bloburl"), "_blank");
//doc.save(`Pago-${numero}.pdf`);
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Area", field: "area", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Nota", field: "numeroVenta", sortable: true },
    { name: "Activa", field: "ventaActiva", sortable: true },
    { name: "Total", field: "total", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroVenta.toLowerCase().includes(search.toLowerCase()) ||
          comment.ventaActiva.toLowerCase().includes(search.toLowerCase()) ||
          comment.total.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedVentaActiva) {
      computedComments = computedComments.filter((e) =>
        e.ventaActiva.includes(selectedVentaActiva)
      );
    }
    if (selectedAreaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedAreaFiltro)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    let Efe = computedComments.map((c) => parseFloat(c.efectivo));
          let TEfe = Efe.reduce((t, efectivo, index) => t + efectivo, 0);
          setTotalTotalEfectivo(TEfe);

          let TDC = computedComments.map((c) => parseFloat(c.tdc));
          let TTDC = TDC.reduce((t, TDC, index) => t + TDC, 0);
          setTotalTotalTDC(TTDC);

          let ComisionTDC = computedComments.map((c) => parseFloat(c.comisionTDC));
          let TComisionTDC = ComisionTDC.reduce((t, ComisionTDC, index) => t + ComisionTDC, 0);
          setTotalTotalComisionTDC(TComisionTDC);

          let Transfer = computedComments.map((c) => parseFloat(c.transfer));
          let TTransfer = Transfer.reduce((t, transfer, index) => t + transfer, 0);
          setTotalTotalTransfer(TTransfer);

          let Cortesia = computedComments.map((c) => parseFloat(c.cortesia));
          let TCortesia = Cortesia.reduce((t, Cortesia, index) => t + Cortesia, 0);
          setTotalTotalCortesia(TCortesia);

          let Total= computedComments.map((c) => parseFloat(c.total));
          let TTotal = Total.reduce((t, Total, index) => t + Total, 0);
          setTotalTotalTotal(TTotal);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "numero" &&
      sorting.field != "total"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "numero" ||
        sorting.field == "total" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "numero"  ||
        sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedVentaActiva,
    selectedAreaFiltro,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function cancelarAbono(idAbono) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Abono!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_ABONOS_TIENDAS}Cancel/${idAbono}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  let totalEfectivo = 0
  let totalTDC = 0
  let totalComisionTDC = 0
  let totalTransfer = 0
  let totalCortesia = 0
  let totalTotal = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.abonosTiendas ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/AbonosTiendasCreate"
                >
                  Nuevo Abono
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Abono
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            {/* <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col> */}
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Abonos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Abonos Venta Cancelada</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAreaFiltro}
                  onChange={(e) => {
                    setSelectedAreaFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.name}</option>
                      );
                    })}
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedVentaActiva}
                  onChange={(e) => {
                    setSelectedVentaActiva(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                  totalTotal = totalTotal + a.total
                // if(a.ventaActiva == "No"){
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.area}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.numeroVenta}</td>
                      <td>{a.ventaActiva}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}
                      </td>
                    </tr>
                  );
                // }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              <td className="negrita" align="center">TOTAL</td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotal)}
                      </td>
                <td></td>
              </tr>

              <tr style={{ backgroundColor: "#00bfff" }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">TOTAL GENERAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalTotal)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ListadoAbonosTiendasCanceladas;
