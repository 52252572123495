import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  Badge
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from 'moment';

function ListadoComisiones() {
  const { user } = useContext(AuthContext);
  const URL_COMISIONES = process.env.REACT_APP_URL_COMISIONES;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;


  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
 
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [importe, setImporte] = useState("");
  const [porcentaje, setPorcentaje] = useState(0);
  const [total, setTotal] = useState("");
  const [importeNuevo, setImporteNuevo] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalPagar, setModalPagar] = useState(false);
  const togglePagar = () => setModalPagar(!modalPagar);
  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("");
  const [idPagar, setIdPagar] = useState("");
  const [idVendedorPagar, setIdVendedorPagar] = useState("");
  const [importePagar, setImportePagar] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{

    axios
      .get(`${URL_COMISIONES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDevoluciones = response.data;
        // Array para pagination
        let arrayTabla = allDevoluciones
          .sort((a, b) => (a.idDevolucion > b.idDevolucion ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active:a.is_active,
              fecha: a.fecha,
              vendedor: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idVendedor: a.colaboradores[0]._id,
              importe: a.importe,
              porcentaje: a.porcentaje,
              numero: a.idComision,
              pagada: a.pagada,
              total: a.total
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 || selectedColaborador == a.idVendedor) 
      ) {
        return [
          a.numero,
          a.fecha,
          a.vendedor,
          a.porcentaje,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Vendedor",
          "Porcentaje",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`Comisiones.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 || selectedColaborador == a.idVendedor)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.vendedor,
          Porcentaje: a.porcentaje,
          Importe: a.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoComisiones";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoComisiones",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Porcentaje",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Porcentaje",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 || selectedColaborador == a.idVendedor) 
      ) {
        return [
          a.numero,
          a.fecha,
          a.vendedor,
          a.porcentaje,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Vendedor",
          "Porcentaje",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Comisiones",
          email: mailTo,
          fileName: "ListadoComisiones.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Comisiones.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 
  // async function PDFOC(
  //   fecha,
  //   clientes,
  //   numero,
  //   idPDFOC,
  //   impuestos,
  //   area,
  //   subTotal,
  //   iva,
  //   total_general,
  //   total_cantidad
  // ) {
  //   await axios
  //     .get(`${URL_ARTICULOS_DEVOLUCIONES}/devolucion/${idPDFOC}`, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       let allArticulosDevolucion = response.data;
  //       let arrayTabla = allArticulosDevolucion
  //         .map((a) => {
  //           return {
  //             id: a._id,
  //             articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
  //             cantidad: a.cantidad,
  //             defectos: a.defectos,
  //             totalPiezas: a.totalPiezas,
  //             precio: a.precio,
  //             total: a.total,
  //           };
  //         })
  //         .filter(function (el) {
  //           return el != null;
  //         });

  //       let dataFinal = Object.values(arrayTabla);

  //       const data = dataFinal.map((a) => {
  //         return [
  //           a.articulos,
  //           new Intl.NumberFormat("en-US").format(a.cantidad),
  //           new Intl.NumberFormat("en-US").format(a.defectos),
  //           new Intl.NumberFormat("en-US").format(a.totalPiezas),
  //           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
  //           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
  //         ];
  //       });
  //       const dataPDF = Object.values(data);
  //       const dataPDFLimpia = dataPDF.filter(function (el) {
  //         return el != null;
  //       });

  //       const doc = new jsPDF("landscape");
  //       var base64 = qrcode.toDataURL(idPDFOC);
  //       var img3 = new Image();
  //       img3.src = base64;
  //       doc.addImage(img3, "png", 230, 17, 25, 25);
  //       var img = new Image();
  //       img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //       doc.addImage(img, "png", 255, 10, 25, 8);
  //       var img2 = new Image();
  //       img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //       doc.addImage(img2, "png", 255, 23, 23, 10);
  //       doc.text(`Devolucion # ${numero}`, 20, 20);
  //       doc.setFontSize(12);
  //       doc.text(`Almacen: ${area}`, 20, 25);
  //       doc.text(`Fecha: ${fecha}`, 20, 30);
  //       doc.text(`Cliente: ${clientes}`, 20, 35);
  //       doc.text(
  //         `Total: ${new Intl.NumberFormat("en-US").format(
  //           total_cantidad
  //         )} piezas`,
  //         20,
  //         40
  //       );

  //       doc.autoTable({
  //         head: [["Articulo", "Cantidad", "Defecto", "Total Piezas", "Precio", "Total"]],
  //         body: dataPDFLimpia,
  //         startY: 42,
  //         foot: [
  //           [
  //             "",
  //             "",
  //             "",
  //             "",
  //             "Sub Total",
  //             new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotal),
  //           ],
  //           [
  //             "",
  //             "",
  //             "",
  //             "",
  //             `IVA ${impuestos}%`,
  //             new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(iva),
  //           ],
  //           [
  //             "",
  //             "",
  //             "",
  //             "",
  //             "Total",
  //             new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_general),
  //           ],
  //         ],
  //         showFoot: "lastPage",
  //       });

  //       window.open(doc.output("bloburl"), "_blank");
//doc.save(`Devolucion-${numero}.pdf`);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }



  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Total", field: "total", sortable: true },
    { name: "Porcentaje", field: "porcentaje", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe.toString().includes(search) 
      );
    }

    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idVendedor.includes(selectedColaborador)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importe" &&
      sorting.field != "total" &&
      sorting.field != "porcentaje" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importe" ||
        sorting.field == "total" ||
        sorting.field == "porcentaje" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importe" ||
        sorting.field == "total" ||
        sorting.field == "porcentaje" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedColaborador,
  ]);

  function  pagarComision(id, idVendedor, importe){
    setIdPagar(id)
    setIdVendedorPagar(idVendedor)
    setImportePagar(importe)
    togglePagar()
  }

 
function pagarComisionBanco(){
  Swal.fire({
    title: "Estas seguro?",
    text: "Se registrará el pago",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Enviar!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
      .patch(
        `${URL_COMISIONES}Pagar/${idPagar}`,
        {
          pagada: "Si",
          colaboradores:idVendedorPagar,
          importe:importePagar
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      )
      .then(()=>{

        axios
        .post(
        `${URL_MOVIMIENTOS_BANCOS}`,
        {
            fecha:endDate,
            importe: -importePagar,
            tipo:"Egreso",
            bancos: selectedBanco,
            comisiones:idPagar,
            moneda,
            tipoCambio:1
        },
        {
            headers: {
            Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
            )}`,
            },
        }
        )

        axios.post(
          URL_LOGS,
          {
            tipo: "Pagar Comision",
            detalle: `Comision: ${idPagar}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }
  });
}
 

function  cancelar(id){
  Swal.fire({
    title: "Estas seguro?",
    text: "Se va a cancelar",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Cancelar!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
      .get(
        `${URL_COMISIONES}Cancel/${id}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      )
      .then(()=>{
        axios.post(
          URL_LOGS,
          {
            tipo: "Cancelar Comision",
            detalle: `Comision: ${id}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }
  });
}
 
function editar(idEdit, vendedor, importe, porcentaje, total){
  setIdEdit(idEdit)
  setVendedor(vendedor)
  setImporte(importe)
  setPorcentaje(porcentaje)
  setTotal(total)
  setImporteNuevo(importe)
  toggle()
}

  function calculaComision(e) {
    setPorcentaje(e);

    let pRes = (total * e) / 100;
    setImporteNuevo(pRes);
  }

  function  editComision(){
    Swal.fire({
      title: "Estas seguro?",
      text: "Se actualizara la comision",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .patch(
          `${URL_COMISIONES}EditImporte/${idEdit}`,
          {
            porcentaje,
            colaboradores:vendedor,
            importe:importeNuevo,
            diferenciaImporte: importe - importeNuevo
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        )
        .then(()=>{
          axios.post(
            URL_LOGS,
            {
              tipo: "Pagar Comision",
              detalle: `Comision: ${idEdit}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
      }
    });
  }

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.comisionesCreate ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
            <Button
                size="sm"
                href="/ComisionesCreate"
                className="btn btn-success"
                id="botonListado"
              >
                Pagar Comision
              </Button>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Comisiones</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Comisiones</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Vendedor</Label>
            <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />

            <tbody>
              {commentsData.map((a) => {
                  return (
                    <tr  style={
                      a.is_active =="No"
                        ? { backgroundColor: "#ed8c8c" }:null
                    }>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.vendedor}</td>
                      <td>{ a.is_active =="Si"?(
                      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)
                      ):("CANCELADA")}</td>
                      <td>{a.porcentaje } %</td>
                      <td>{ a.is_active =="Si"?(
                      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe)
                      ):("CANCELADA")}</td>
                      <td>{a.aplicada}</td>
                      <td>
                        {a.is_active == "Si"?(
                        a.pagada =="No"?(
                          <>
                      <Button size="sm"
                      className="btn"
                      color="primary"
                      onClick={(e) =>
                       pagarComision(
                          a._id,
                          a.idVendedor,
                          a.importe
                        )
                      }
                    >
                    <i class="far fa-check-circle"></i>
                      </Button>
                      <Button size="sm"
                      className="btn"
                      color="info"
                      onClick={(e) =>
                       editar(
                          a._id,
                          a.idVendedor,
                          a.importe,
                          a.porcentaje,
                          a.total
                        )
                      }
                    >
                    <i class="far fa-edit"></i>
                      </Button>

                        <Button size="sm"
                        className="btn"
                        color="danger"
                        onClick={(e) =>
                         cancelar(
                            a._id,
                          )
                        }
                        >
                        <i class="far fa-times-circle"></i>
                        </Button>
                        </>
                        ):undefined

                        ):undefined}

                            {/* <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.clientes,
                                  a.numero,
                                  a._id,
                                  a.impuestos,
                                  a.area,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.total_cantidad
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button> */}
                         
                    </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar</ModalHeader>
        <ModalBody>
        <Table>
                       

                        <tr>
                          <th>Total</th>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(total)}
                          </td>
                        </tr>

                        <tr>
                          <th>Porcentaje</th>
                          <td align="right">
                            <Row>
                              <Col md={7}></Col>
                              <Col md={5}>
                                <Input
                                  bsSize="sm"
                                  type="number"
                                  min="0"
                                  step="any"
                                  value={porcentaje}
                                  onChange={(e) => {
                                    calculaComision(e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </td>
                        </tr>

                        <tr>
                          <th>Comision a Pagar</th>
                          <td style={{ "font-weight": "bold" }} align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(importeNuevo)}
                          </td>
                        </tr>
                      </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editComision}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalPagar} toggle={togglePagar}>
        <ModalHeader toggle={togglePagar}>Pagar Comision</ModalHeader>
        <ModalBody>

        <Col md={6}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={pagarComisionBanco}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoComisiones;
