import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function ListadoVentas() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_DEPARTAMENTOS_CLIENTES =
    process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [surtidos, setSurtidos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
    axios
      .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            if (a.is_active == "Si") {
              return {
                _id: a._id,
                is_active: a.is_active,
                fecha: a.fecha,
                user: a.user[0].nombre + " " + a.user[0].apellido,
                idUser: a.user[0]._id,
                pedidos: a.pedidos[0].idPedido,
                idPedido: a.pedidos[0]._id,
                clientes: a.pedidos[0].clientes[0].nombre_comercial,
                codigoClientes: a.pedidos[0].clientes[0].codigo,
                idClientes: a.pedidos[0].clientes[0]._id,
                saldoClientes: a.pedidos[0].clientes[0].saldo,
                observaciones: a.observaciones,
                numero: a.idSurtido,
                comisionTDC: a.pedidos[0].comisionTDC,
                comisionFlete: a.pedidos[0].comisionFlete,
                impuestos: a.pedidos[0].impuestos,
                subTotal: a.subTotal,
                iva: a.iva,
                total_general: a.total_general,
                idDirEnt: a.pedidos[0].sucursales,
                alias: a.pedidos[0].sucursales[0].alias,
                calle: a.pedidos[0].sucursales[0].calle,
                numero_ext: a.pedidos[0].sucursales[0].numero_ext,
                numero_int: a.pedidos[0].sucursales[0].numero_int,
                pais: a.pedidos[0].sucursales[0].pais,
                estado: a.pedidos[0].sucursales[0].estado,
                ciudad: a.pedidos[0].sucursales[0].ciudad,
                delegacion: a.pedidos[0].sucursales[0].delegacion,
                colonia: a.pedidos[0].sucursales[0].colonia,
                cp: a.pedidos[0].sucursales[0].cp,
                paqueteria: a.pedidos[0].sucursales[0].paqueteria,
                RFC: a.pedidos[0].sucursales[0].RFC,
                area: a.pedidos[0].areas[0].name,
                vendedor:
                  a.pedidos[0].colaboradores[0].nombre +
                  " " +
                  a.pedidos[0].colaboradores[0].apellido,
                telefono: a.pedidos[0].clientes[0].telefono,
                idDepartamento: a.clientes[0].departamentosClientes[0]._id,
                departamentoName: a.clientes[0].departamentosClientes[0].name,
              };
            } else {
              return {
                _id: a._id,
                is_active: a.is_active,
                fecha: a.fecha,
                user: a.user[0].nombre + " " + a.user[0].apellido,
                idUser: a.user[0]._id,
                pedidos: a.pedidos[0].idPedido,
                idPedido: a.pedidos[0]._id,
                clientes: a.pedidos[0].clientes[0].nombre_comercial,
                codigoClientes: a.pedidos[0].clientes[0].codigo,
                idClientes: a.pedidos[0].clientes[0]._id,
                saldoClientes: a.pedidos[0].clientes[0].saldo,
                observaciones: a.observaciones,
                numero: a.idSurtido,
                comisionTDC: 0,
                comisionFlete: 0,
                impuestos: 0,
                subTotal: 0,
                iva: 0,
                total_general: 0,
                idDirEnt: a.pedidos[0].sucursales,
                alias: a.pedidos[0].sucursales[0].alias,
                calle: a.pedidos[0].sucursales[0].calle,
                numero_ext: a.pedidos[0].sucursales[0].numero_ext,
                numero_int: a.pedidos[0].sucursales[0].numero_int,
                pais: a.pedidos[0].sucursales[0].pais,
                estado: a.pedidos[0].sucursales[0].estado,
                ciudad: a.pedidos[0].sucursales[0].ciudad,
                delegacion: a.pedidos[0].sucursales[0].delegacion,
                colonia: a.pedidos[0].sucursales[0].colonia,
                cp: a.pedidos[0].sucursales[0].cp,
                paqueteria: a.pedidos[0].sucursales[0].paqueteria,
                RFC: a.pedidos[0].sucursales[0].RFC,
                area: a.pedidos[0].areas[0].name,
                vendedor:
                  a.pedidos[0].colaboradores[0].nombre +
                  " " +
                  a.pedidos[0].colaboradores[0].apellido,
                telefono: a.pedidos[0].clientes[0].telefono,
                idDepartamento: a.clientes[0].departamentosClientes[0]._id,
                departamentoName: a.clientes[0].departamentosClientes[0].name,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );

  function PDFTabla() {
    let totalTotal = 0;
    const data = ListaSurtidos.map((a) => {
      totalTotal = totalTotal + a.total_general;
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Importe"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    window.open(doc.output("bloburl"), "_blank");
    //doc.save(`Notas.pdf`);
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Importe: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: ["Numero", "Fecha", "Cliente", "Importe"],
        sheetHeader: ["Numero", "Fecha", "Cliente", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalTotal = 0;
    const data = ListaSurtidos.map((a) => {
      totalTotal = totalTotal + a.total_general;
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Importe"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function cancelarNota(idNota) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara la Nota!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_SURTIDOS}Cancel/${idNota}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  async function PDFOCTicket(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono,
    saldoClientes
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosVenta = response.data;
        let arrayTabla = allArticulosVenta
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos:
                a.articulos[0].nombre +
                " " +
                a.articulos[0].lineas[0].name +
                " " +
                a.articulos[0].familias[0].name,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let totalKG = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = agrupadoValues.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let cajas = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TC = cajas.reduce((t, total, index) => t + total, 0);

        let LA = agrupadoValues.length * 1.5;
        let largoTotal = LA + 4 + 7;

        let totalParcial = 0;

        let subTotalTabla = 0;
        let total_general_tabla = 0;
        let iva_tabla = 0;
        let impuesto_tabla = impuestos;

        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          subTotalTabla = subTotalTabla + totalParcial;
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.cantidad * a.precio),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        iva_tabla = (subTotalTabla * impuesto_tabla) / 100;
        total_general_tabla =
          subTotalTabla + comisionTDC + comisionFlete + iva_tabla;

        const doc = new jsPDF("p", "cm", [8, largoTotal]);
        var base64 = qrcode.toDataURL(idPDFOC);
        var img3 = new Image();
        img3.src = base64;

        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";

        doc.addImage(img2, "png", 5, 1, 2, 2);

        doc.setFontSize(6);
        doc.text(`Tandera SA de CV / RFC: TAN090216V4A`, 0.3, 1);
        doc.text(`Mixcalco 32`, 0.3, 1.3);
        doc.text(`Colonia: Centro CDMX`, 0.3, 1.6);
        doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, 0.3, 1.9);
        doc.text(`Fecha: ${fecha}`, 0.3, 2.2);
        doc.text(`Nota # ${numero} / Almacen: ${area}`, 0.3, 2.5);
        doc.text(
          `Piezas: ${new Intl.NumberFormat("en-US").format(
            TK
          )} / Cajas ${TC.toFixed(2)}`,
          0.3,
          2.8
        );
        doc.text(`Cliente: ${clientes}`, 0.3, 3.1);
        doc.text(`Vendedor: ${user}`, 0.3, 3.4);
        doc.autoTable({
          head: [["Articulo", "Cant", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
          },
          startY: 3.8,
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
          foot: [
            [
              "",
              { content: "SubTotal", colSpan: 2, styles: { halign: "left" } },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(subTotalTabla),
            ],
            // [
            //   "",
            //   { content: `Descuento ${descuento}%`, colSpan: 2, styles: { halign: 'left' } },
            //   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(descuentoNumero),
            // ],
            [
              "",
              { content: `Comision`, colSpan: 2, styles: { halign: "left" } },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva_tabla),
            ],
            [
              "",
              {
                content: "Comision TDC",
                colSpan: 2,
                styles: { halign: "left" },
              },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC),
            ],
            [
              "",
              {
                content: "Comision Flete",
                colSpan: 2,
                styles: { halign: "left" },
              },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete),
            ],
            [
              "",
              { content: "Total", colSpan: 2, styles: { halign: "left" } },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general_tabla),
            ],
            [
              "",
              { content: "Saldo", colSpan: 2, styles: { halign: "left" } },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(saldoClientes),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.addImage(
          img3,
          "png",
          0.3,
          doc.autoTable.previous.finalY - 3.5,
          3,
          3
        );

        window.open(doc.output("bloburl"), "_blank");
        //doc.save(`Nota-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos:
                a.articulos[0].nombre +
                " " +
                a.articulos[0].lineas[0].name +
                " " +
                a.articulos[0].familias[0].name,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 15, 15);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`Nota:`, 35, 20);
        doc.text(`${numero}`, 60, 20);
        doc.text(`Pedido:`, 35, 25);
        doc.text(`${pedidos}`, 60, 25);

        // doc.text(`Ejecutivo:`, 120, 15); doc.text(`${vendedor}`, 150, 15);
        doc.text(`Almacen:`, 120, 15);
        doc.text(`${area}`, 150, 15);

        doc.setFontSize(8);
        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 10, 32);
        doc.text(`RFC: ${RFC}`, 150, 32);
        doc.text(`Sucursal: ${alias}`, 10, 37);
        doc.text(
          `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${telefono}`,
          10,
          42
        );
        doc.text(
          `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp} Paqueteria: ${paqueteria}`,
          10,
          47
        );

        doc.autoTable({
          head: [["Articulo", "Cajas", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 50,
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(subTotal.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Comision Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general.toFixed(2)),
            ],
          ],
          showFoot: "lastPage",
        });

        window.open(doc.output("bloburl"), "_blank");
        //doc.save(`Nota-${numero}.pdf`);
        //   }
        // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Departamento", field: "departamentoName", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentoName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search) ||
          comment.total_general.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero" &&
      sorting.field != "total_general"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" ||
        sorting.field == "numero" ||
        sorting.field == "total_general")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" ||
        sorting.field == "numero" ||
        sorting.field == "total_general")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
    selectedDepartamento,
  ]);

  let totalImporteTabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_ventas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Notas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ventas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Departamento</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedDepartamento}
                onChange={(e) => {
                  setSelectedDepartamento(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {departamentos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if (a.is_active == "No") {
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.departamentoName}</td>
                      <td>
                        {a.is_active == "Si"
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.total_general)
                          : undefined}
                      </td>
                      <td>CANCELADA</td>
                    </tr>
                  );
                } else {
                  totalImporteTabla = totalImporteTabla + a.total_general;
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.departamentoName}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total_general)}
                      </td>
                      <td>
                        <>
                          <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) =>
                              PDFOCTicket(
                                a.fecha,
                                a.idClientes,
                                a.clientes,
                                a.observaciones,
                                a.numero,
                                a._id,
                                a.user,
                                a.pedidos,
                                a.comisionTDC,
                                a.comisionFlete,
                                a.impuestos,
                                a.subTotal,
                                a.iva,
                                a.total_general,
                                a.alias,
                                a.calle,
                                a.numero_ext,
                                a.numero_int,
                                a.pais,
                                a.estado,
                                a.ciudad,
                                a.delegacion,
                                a.colonia,
                                a.cp,
                                a.RFC,
                                a.paqueteria,
                                a.area,
                                a.codigoClientes,
                                a.vendedor,
                                a.telefono,
                                a.saldoClientes
                              )
                            }
                          >
                            <i class="fas fa-ticket-alt"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.idClientes,
                                a.clientes,
                                a.observaciones,
                                a.numero,
                                a._id,
                                a.user,
                                a.pedidos,
                                a.comisionTDC,
                                a.comisionFlete,
                                a.impuestos,
                                a.subTotal,
                                a.iva,
                                a.total_general,
                                a.alias,
                                a.calle,
                                a.numero_ext,
                                a.numero_int,
                                a.pais,
                                a.estado,
                                a.ciudad,
                                a.delegacion,
                                a.colonia,
                                a.cp,
                                a.RFC,
                                a.paqueteria,
                                a.area,
                                a.codigoClientes,
                                a.vendedor,
                                a.telefono
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) => cancelarNota(a._id)}
                          >
                            <i class="fas fa-ban"></i>
                          </Button>
                        </>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalImporteTabla)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoVentas;
