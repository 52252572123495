import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import PasswordCliente from "./PasswordCliente";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";

function ListadoClientes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_DEPARTAMENTOS_CLIENTES = process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CAMARONES = process.env.REACT_APP_AREAS_CAMARONES;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SUCURSALES = process.env.REACT_APP_URL_SUCURSALES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;

  const [clientes, setClientes] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [mailTo, setMailTo] = useState("");
  const [idPassword, setIdPassword] = useState("");
  const [idEditCliente, setIdEditCliente] = useState("");
  const [selectedGrupoEdit, setSelectedGrupoEdit] = useState("");
  const [selectedDepartamentoEdit, setSelectedDepartamentoEdit] = useState("");
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("");
  const [RFC, setRFC] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [codigo, setCodigo] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [emailViejo, setEmailViejo] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [selectedVendedorName, setSelectedVendedorName] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [ultimo_pago, setUltimoPago] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaEdit, setSelectedAreaEdit] = useState("");
  const [plazo, setPlazo] = useState("");
  const [regFiscal, setRegFiscal] = useState("");

  // Info
  const [idInfo, setIdInfo] = useState("");
  const [telefono_info, setTelefonoInfo] = useState("");
  const [nombre_comercial_info, setNombreComercialInfo] = useState("");
  const [email_info, setEmailInfo] = useState("");
  const [forma_pago_info, setFormaPagoInfo] = useState("");
  const [razon_social_info, setRazonSocialInfo] = useState("");
  const [grupo_info, setGruposInfo] = useState("");
  const [direccion_info, setDireccionInfo] = useState("");
  const [colaborador_info, setColaboradorInfo] = useState("");
  const [observaciones_info, setObservacionesInfo] = useState("");

  // Direcciones Sucursal
  const [sucursales, setSucursales] = useState([]);
  const [idClienteSucursal, setIdClienteSucursal] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalSucursal, setModalSucursal] = useState(false);
  const toggleSucursal = () => setModalSucursal(!modalSucursal);

  const [abrirSucursal, setAbrirSucursal] = useState(false);

  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);

  const [activo, setActivo] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [grupo, setGrupo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      paqueteria: "",
      alias: "",
      calle: "",
      numero_ext: "",
      numero_int: "",
      delegacion: "",
      estado: "",
      pais: "",
      cp: "",
      colonia: "",
      ciudad: "",
      RFC:"",
      razon_social:"",
      regFiscal: ""
    },
  ]);

  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              telefono: a.telefono,
              email: a.email,
              calle: a.calle,
              numero_ext: a.numero_ext,
              numero_int: a.numero_int,
              pais: a.pais,
              estado: a.estado,
              ciudad: a.ciudad,
              delegacion: a.delegacion,
              colonia: a.colonia,
              codigo: a.codigo,
              cp: a.cp,
              nombre_comercial: a.nombre_comercial,
              razon_social: a.razon_social,
              RFC: a.RFC,
              grupo: a.grupos[0].name,
              idGrupo: a.grupos[0]._id,
              departamento: a.departamentosClientes[0].name,
              idDepartamento: a.departamentosClientes[0]._id,
              vendedor: a.vendedor[0].nombre + " " + a.vendedor[0].apellido,
              idVendedor: a.vendedor[0]._id,
              observaciones: a.observaciones,
              ultimo_pago: a.ultimo_pago,
              forma_pago: a.forma_pago,
              area: a.areas[0].name,
              idArea: a.areas[0]._id,
              regFiscal: a.regFiscal,
              plazo: a.plazo
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setClientes(allClientes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupos) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.activo)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupo,
          a.vendedor,
          a.area
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Codigo", "Nombre", "Telefono", "Mail", "Grupo", "Vendedor", "Almacen"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`Clientes.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupos) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.activo)
      ) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre_comercial,
          RazonSocial :a.razon_social,
          RFC: a.RFC,
          Calle: a.calle,
          NoExt: a.numero_ext,
          NoInt: a.numero_int,
          Pais: a.pais,
          Estado: a.estado,
          Ciudad: a.ciudad,
          Delegacion: a.delegacion,
          Colonia: a.colonia,
          CP: a.cp,
          Telefono: a.telefono,
          Mail: a.email,
          Vendedor: a.vendedor,
          FormaPago: a.forma_pago,
          Plazo: a.plazo,
          RegFiscal: a.regFiscal,
          Grupo: a.grupo,
          Departamento: a.departamento,
          Almacen: a.area,
          Observaciones: a.observaciones
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoClientes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoClientes",
        sheetFilter: ["Codigo", "Nombre", "RazonSocial", "RFC", "Calle", "NoExt", "NoInt", "Pais", "Estado", "Ciudad", "Delegacion", "Colonia", "CP", "Telefono", "Mail", "Vendedor", "FormaPago", "Plazo", "RegFiscal", "Grupo", "Departamento", "Almacen", "Observaciones"],
        sheetHeader: ["Codigo", "Nombre", "RazonSocial", "RFC", "Calle", "NoExt", "NoInt", "Pais", "Estado", "Ciudad", "Delegacion", "Colonia", "CP", "Telefono", "Mail", "Vendedor", "FormaPago", "Plazo", "RegFiscal", "Grupo", "Departamento", "Almacen", "Observaciones"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupos) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.activo)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.telefono,
          a.email,
          a.grupo,
          a.vendedor,
          a.area
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Codigo", "Nombre", "Telefono", "Mail", "Grupo", "Vendedor", "Almacen"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Clientes",
          email: mailTo,
          fileName: "ListadoClientes.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    idEdit,
    telefono,
    email,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    nombre_comercial,
    razon_social,
    RFC,
    gruposId,
    vendedorNombre,
    vendedorId,
    observaciones,
    ultimo_pago,
    forma_pago,
    codigo,
    departamentoId,
    idArea,
    plazo,
    regFiscal,
  ) {
    setTelefono(telefono);
    setEmail(email);
    setEmailViejo(email);
    setCalle(calle);
    setNumeroExt(numero_ext);
    setNumeroInt(numero_int);
    setPais(pais);
    setEstado(estado);
    setCiudad(ciudad);
    setDelegacion(delegacion);
    setColonia(colonia);
    setCP(cp);
    setNombreComercial(nombre_comercial);
    setRazonSocial(razon_social);
    setRFC(RFC);
    setSelectedGrupoEdit(gruposId);
    setSelectedDepartamentoEdit(departamentoId);
    setSelectedVendedorName(vendedorNombre);
    setSelectedVendedor(vendedorId);
    setObservaciones(observaciones);
    setUltimoPago(ultimo_pago);
    setFormaPago(forma_pago);
    setCodigo(codigo);
    setIdEditCliente(idEdit);
    setSelectedAreaEdit(idArea);
    setPlazo(plazo)
    setRegFiscal(regFiscal)
    toggleEdit();
  }

  function Info(
    id,
    nombre_comercial,
    telefono,
    email,
    forma_pago,
    razon_social,
    grupos,
    calle,
    numero_ext,
    numero_int,
    colonia,
    colaboradoresName,
    observaciones
  ) {
    setIdInfo(id);
    setNombreComercialInfo(nombre_comercial);
    setTelefonoInfo(telefono);
    setEmailInfo(email);
    setFormaPagoInfo(forma_pago);
    setRazonSocialInfo(razon_social);
    setGruposInfo(grupos);
    setDireccionInfo(
      calle + " " + numero_ext + " " + numero_int + " " + colonia
    );
    setColaboradorInfo(colaboradoresName);
    setObservacionesInfo(observaciones);
    toggle();
  }

  function editClientes(event) {
    event.preventDefault();
    const URL_CLIENTES_EDIT = `${process.env.REACT_APP_URL_CLIENTES}/${idEditCliente}`;
    axios
      .all([
        axios.patch(
          URL_CLIENTES_EDIT,
          {
            nombre_comercial,
            razon_social,
            RFC,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            telefono,
            email,
            grupos: selectedGrupoEdit,
            departamentosClientes: selectedDepartamentoEdit,
            vendedor: selectedVendedor,
            observaciones,
            ultimo_pago,
            forma_pago,
            // codigo,
            areas: selectedAreaEdit,
            plazo,
            regFiscal
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Cliente",
            detalle: `${nombre_comercial}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Codigo", field: "codigo", sortable: false },
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Telefono", field: "telefono", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Grupo", field: "grupo", sortable: true },
    { name: "Departamento", field: "departamento", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase()) ||
          comment.grupo.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toString().includes(search) ||
          comment.telefono.toString().includes(search)
      );
    }
    if (grupo) {
      computedComments = computedComments.filter((e) =>
        e.idGrupo.includes(grupo)
      );
    }
    if (vendedor) {
      computedComments = computedComments.filter((e) =>
        e.idVendedor.includes(vendedor)
      );
    }
    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "codigo" ||
      sorting.field == "telefono")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "codigo" ||
      sorting.field == "telefono")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    vendedor,
    grupo,
    activo,
    selectedDepartamento,
    selectedArea
  ]);

  function dir(id, nombre_comercial) {
    setIdClienteSucursal(id);
    setNombreComercial(nombre_comercial)
    axios
      .get(`${URL_SUCURSALES}/clientes/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSucursales = res.data;
        setSucursales(allSucursales);
      })
      .catch((err) => {
        console.log(err);
      });
    setAbrirSucursal(true);
  }

  const handleChangeInputExistentes = (id, event) => {
    const newSucursales = sucursales.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setSucursales(newSucursales);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function saveDireccion() {
    let totalDir = sucursales.length + inputFields.length;
    sucursales.map((a) => {
      if (a.alias != "") {
        axios
          .patch(
            `${URL_SUCURSALES}/${a._id}`,
            {
              paqueteria: a.paqueteria,
              alias: a.alias,
              calle: a.calle,
              numero_ext: a.numero_ext,
              numero_int: a.numero_int,
              delegacion: a.delegacion,
              estado: a.estado,
              pais: a.pais,
              cp: a.cp,
              colonia: a.colonia,
              ciudad: a.ciudad,
              RFC: a.RFC,
              razon_social: a.razon_social,
              regFiscal: a.regFiscal
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalDir = totalDir - 1;
            if (totalDir == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Sucursal",
                  detalle: `Cliente ${idClienteSucursal} / Alias ${a.alias}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } else {
        totalDir = totalDir - 1;
      }
    });

    // agregar inputFields
    inputFields.map((a) => {
      if (a.alias != "") {
        axios
          .post(
            `${URL_SUCURSALES}`,
            {
              paqueteria: a.paqueteria,
              alias: a.alias,
              calle: a.calle,
              numero_ext: a.numero_ext,
              numero_int: a.numero_int,
              delegacion: a.delegacion,
              estado: a.estado,
              pais: a.pais,
              cp: a.cp,
              colonia: a.colonia,
              ciudad: a.ciudad,
              clientes: idClienteSucursal,
              RFC: a.RFC,
              razon_social: a.razon_social,
              regFiscal: a.regFiscal
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalDir = totalDir - 1;
            if (totalDir == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Sucursal",
                  detalle: `Cliente ${idClienteSucursal} / Alias ${a.alias}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } else {
        totalDir = totalDir - 1;
      }
    });
  }

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        paqueteria: "",
        alias: "",
        calle: "",
        numero_ext: "",
        numero_int: "",
        delegacion: "",
        estado: "",
        pais: "",
        cp: "",
        colonia: "",
        ciudad: "",
        RFC:"",
        razon_social:"",
        regFiscal: ""
      },
    ]);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_clientes ? (
        <div className="card col-12">
        {!abrirSucursal ? (
            <>
          <Row>
            <Col md={8}>
              {user.clientes_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ClientesCreate"
                >
                  Nuevo Cliente
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Cliente
                </Button>
              )}
              {/* <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Clientes</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Clientes</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Grupos</Label>
              <Input
                bsSize="sm"
                type="select"
                value={grupo}
                onChange={(e) => {
                  setGrupo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {grupos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            {user.departamentos == AREAS_GENERAL ? (
              <Col md={2}>
                <Label>Departamento</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedDepartamento}
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            ) : undefined}

            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={vendedor}
                onChange={(e) => {
                  setVendedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td>{p.codigo}</td>
                      <td> {p.nombre_comercial}</td>
                      <td>{p.telefono}</td>
                      <td>{p.email}</td>
                      <td>{p.area}</td>
                      <td>{p.grupo}</td>
                      <td>{p.departamento}</td>
                      <td>{p.vendedor}</td>
                      <td>
                        {user.clientes_create ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => dir(p._id, p.nombre_comercial)}
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                            <Button
                              color="success"
                              size="sm"
                              onClick={(e) =>
                                Info(
                                  p._id,
                                  p.nombre_comercial,
                                  p.telefono,
                                  p.email,
                                  p.forma_pago,
                                  p.razon_social,
                                  p.grupo,
                                  p.calle,
                                  p.numero_ext,
                                  p.numero_int,
                                  p.colonia,
                                  p.vendedor,
                                  p.observaciones
                                )
                              }
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  p._id,
                                  p.telefono,
                                  p.email,
                                  p.calle,
                                  p.numero_ext,
                                  p.numero_int,
                                  p.pais,
                                  p.estado,
                                  p.ciudad,
                                  p.delegacion,
                                  p.colonia,
                                  p.cp,
                                  p.nombre_comercial,
                                  p.razon_social,
                                  p.RFC,
                                  p.idGrupo,
                                  p.vendedor,
                                  p.idVendedor,
                                  p.observaciones,
                                  p.ultimo_pago,
                                  p.forma_pago,
                                  p.codigo,
                                  p.idDepartamento,
                                  p.idArea,
                                  p.plazo,
                                  p.regFiscal,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            {/* <Button
                              color="success"
                              id="Password"
                              size="sm"
                              onClick={(e) => jalaInfoPassword(p._id)}
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button> */}
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_CLIENTES}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Password"
                              size="sm"
                              disabled
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
          </>
          ) : (
            <div className="card container col-md-12">
               <h3 align="center">Sucursales {nombre_comercial}</h3>
              {sucursales.map((ao) => (
            <div key={ao._id}>
              <Row>
                <Col md={2}>
                <Label className="mr-sm-2">Alias</Label>
                  <Input
                    // bsSize="sm"
                    name="alias"
                    type="text"
                    value={ao.alias}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Calle</Label>
                  <Input
                    // bsSize="sm"
                    name="calle"
                    type="text"
                    value={ao.calle}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>

                <Col md={1}>
                <Label className="mr-sm-2"># Ext</Label>
                  <Input
                    // bsSize="sm"
                    name="numero_ext"
                    type="text"
                    value={ao.numero_ext}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2"># Int</Label>
                  <Input
                    // bsSize="sm"
                    name="numero_int"
                    type="text"
                    value={ao.numero_int}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">Pais</Label>
                  <Input
                    // bsSize="sm"
                    name="pais"
                    type="text"
                    value={ao.pais}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Estado</Label>
                  <Input
                    // bsSize="sm"
                    name="estado"
                    type="text"
                    value={ao.estado}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Ciudad</Label>
                  <Input
                    // bsSize="sm"
                    name="ciudad"
                    type="text"
                    value={ao.ciudad}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                </Row>
                <Row>
                <Col md={2}>
                <Label className="mr-sm-2">Delegacion</Label>
                  <Input
                    // bsSize="sm"
                    name="delegacion"
                    type="text"
                    value={ao.delegacion}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Colonia</Label>
                  <Input
                    // bsSize="sm"
                    name="colonia"
                    type="text"
                    value={ao.colonia}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">CP</Label>
                  <Input
                    // bsSize="sm"
                    name="cp"
                    type="text"
                    value={ao.cp}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">Paqueteria</Label>
                  <Input
                    // bsSize="sm"
                    name="paqueteria"
                    type="text"
                    value={ao.paqueteria}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">RFC</Label>
                  <Input
                    // bsSize="sm"
                    name="RFC"
                    type="text"
                    value={ao.RFC}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Razon Social</Label>
                  <Input
                    // bsSize="sm"
                    name="razon_social"
                    type="text"
                    value={ao.razon_social}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Reg Fiscal</Label>
                  <Input
                    // bsSize="sm"
                    name="regFiscal"
                    type="select"
                    value={ao.regFiscal}
                    required
                    onChange={(event) => {
                      handleChangeInputExistentes(ao._id, event);
                    }}
                    >
                    <option value="">Reg Fiscal</option>
                 <option value="0">0</option>
                 <option value="601">601 General de Ley Personas Morales</option>
                 <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                 <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                 <option value="608">608 Demas Ingresos</option>
                 <option value="612">612 Personas Fisicas con Actividad Empresarial</option>
                 <option value="616">616 Sin Obligacion Fiscal</option>
                 <option value="621">621 Incorporacion Fiscal</option>
                 <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                 <option value="626">626 Regimen simplificado de Confianza</option>
                 </Input>
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">Alta / Baja</Label>
                <br />
                <Baja
                    idStatus={ao._id}
                    is_active={ao.is_active}
                    URL_BAJA={process.env.REACT_APP_URL_SUCURSALES}
                  />
                </Col>
              </Row>
              <br />
            </div>
          ))}

          {/* Agregar mas direcciones */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                <Label className="mr-sm-2">Alias</Label>
                  <Input
                    // bsSize="sm"
                    name="alias"
                    type="text"
                    value={inputField.alias}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>

                <Col md={2}>
                <Label className="mr-sm-2">Calle</Label>
                  <Input
                    // bsSize="sm"
                    name="calle"
                    type="text"
                    value={inputField.calle}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>

                <Col md={1}>
                <Label className="mr-sm-2"># Ext</Label>
                  <Input
                    // bsSize="sm"
                    name="numero_ext"
                    type="text"
                    value={inputField.numero_ext}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2"># Int</Label>
                  <Input
                    // bsSize="sm"
                    name="numero_int"
                    type="text"
                    value={inputField.numero_int}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">Pais</Label>
                  <Input
                    // bsSize="sm"
                    name="pais"
                    type="text"
                    value={inputField.pais}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Estado</Label>
                  <Input
                    // bsSize="sm"
                    name="estado"
                    type="text"
                    value={inputField.estado}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Ciudad</Label>
                  <Input
                    // bsSize="sm"
                    name="ciudad"
                    type="text"
                    value={inputField.ciudad}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                </Row>
                <Row>
                <Col md={2}>
                <Label className="mr-sm-2">Delegacion</Label>
                  <Input
                    // bsSize="sm"
                    name="delegacion"
                    type="text"
                    value={inputField.delegacion}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Colonia</Label>
                  <Input
                    // bsSize="sm"
                    name="colonia"
                    type="text"
                    value={inputField.colonia}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">CP</Label>
                  <Input
                    // bsSize="sm"
                    name="cp"
                    type="text"
                    value={inputField.cp}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">Paqueteria</Label>
                  <Input
                    // bsSize="sm"
                    name="paqueteria"
                    type="text"
                    value={inputField.paqueteria}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={1}>
                <Label className="mr-sm-2">RFC</Label>
                  <Input
                    // bsSize="sm"
                    name="RFC"
                    type="text"
                    value={inputField.RFC}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Razon Social</Label>
                  <Input
                    // bsSize="sm"
                    name="razon_social"
                    type="text"
                    value={inputField.razon_social}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Reg Fiscal</Label>
                  <Input
                    // bsSize="sm"
                    name="regFiscal"
                    type="select"
                    value={inputField.regFiscal}
                    required
                    onChange={(event) => {
                      handleChangeInput(inputField.id, event);
                    }}
                  >
                     <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Fisicas con Actividad Empresarial</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
                  </Input>
                </Col>
                <Col md={1}>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
              <br />
            </div>
          ))}
          <Col md={2}>
          <Button size="sm" color="success" onClick={saveDireccion}>
            Guardar
          </Button>
          <Button
                  onClick={(e) => setAbrirSucursal(false)}
                  className="btn btn-danger"
                  id="botonListado"
                  size="sm"
                >
                  Regresar
                </Button>
          </Col>
            </div> 
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Informacion del Cliente</h4>
        </ModalHeader>
        <ModalBody>
          <div className="card container">
            <Table size="sm" borderless>
              <tbody>
                <tr>
                  <td>Nombre Comercial</td>
                  <td>{nombre_comercial_info}</td>
                </tr>
                <tr>
                  <td>Telefono</td>
                  <td>{telefono_info}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{email_info}</td>
                </tr>
                <tr>
                  <td>Forma de Pago</td>
                  <td>{forma_pago_info}</td>
                </tr>
                <tr>
                  <td>Razon Social</td>
                  <td>{razon_social_info}</td>
                </tr>
                <tr>
                  <td>Grupo</td>
                  <td>{grupo_info}</td>
                </tr>
                <tr>
                  <td>Direccion</td>
                  <td>{direccion_info}</td>
                </tr>
                <tr>
                  <td>Cobrador</td>
                  <td>{colaborador_info} </td>
                </tr>
                <tr>
                  <td>Observaciones</td>
                  <td>{observaciones_info}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
        <ModalHeader toggle={togglePassword}>
          <h4>Cambio de contraseña</h4>
        </ModalHeader>
        <ModalBody>
          <PasswordCliente idCliente={idPassword} />
        </ModalBody>
      </Modal>

      
      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar cliente {nombre_comercial}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {/* <Col md={3}>
              <Label>Codigo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={codigo}
                required
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              />
            </Col> */}
            <Col md={3}>
              <Label>Nombre Comercial</Label>
              <Input
                type="text"
                placeholder="Nombre Comercial"
                value={nombre_comercial}
                required
                onChange={(e) => {
                  setNombreComercial(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Razon Social</Label>
              <Input
                type="text"
                placeholder="Razon Social"
                value={razon_social}
                required
                onChange={(e) => {
                  setRazonSocial(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>RFC</Label>
              <Input
                type="text"
                placeholder="RFC"
                value={RFC}
                required
                onChange={(e) => {
                  setRFC(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Label>Calle</Label>
              <Input
                type="text"
                placeholder="Calle"
                value={calle}
                required
                onChange={(e) => {
                  setCalle(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>No. Ext</Label>
              <Input
                type="text"
                placeholder="No. Ext"
                value={numero_ext}
                required
                onChange={(e) => {
                  setNumeroExt(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>No. Int</Label>
              <Input
                type="text"
                placeholder="No. Int"
                value={numero_int}
                required
                onChange={(e) => {
                  setNumeroInt(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Pais</Label>
              <Input
                type="text"
                placeholder="Pais"
                value={pais}
                required
                onChange={(e) => {
                  setPais(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
           
            <Col md={3}>
              <Label>Estado</Label>
              <Input
                type="text"
                placeholder="Estado"
                value={estado}
                required
                onChange={(e) => {
                  setEstado(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Ciudad</Label>
              <Input
                type="text"
                placeholder="Ciudad"
                value={ciudad}
                required
                onChange={(e) => {
                  setCiudad(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Delegacion</Label>
              <Input
                type="text"
                placeholder="Delegacion o Municipio"
                value={delegacion}
                required
                onChange={(e) => {
                  setDelegacion(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Colonia</Label>
              <Input
                type="text"
                placeholder="Colonia"
                value={colonia}
                required
                onChange={(e) => {
                  setColonia(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>CP</Label>
              <Input
                type="text"
                placeholder="CP"
                value={cp}
                required
                onChange={(e) => {
                  setCP(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Telefono</Label>
              <Input
                type="text"
                placeholder="Telefono"
                value={telefono}
                required
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Vendedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedVendedor}
                required
                onChange={(e) => {
                  setSelectedVendedor(e.target.value);
                }}
              >
                <option value="0">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

          </Row>

          <Row>
            <Col md={3}>
              <Label>Forma de Pago</Label>
              <Input
                type="select"
                placeholder="Forma de Pago"
                value={forma_pago}
                required
                onChange={(e) => {
                  setFormaPago(e.target.value);
                }}
              >
                  <option value="0">Forma de Pago</option>
                  <option value="Contado">Contado</option>
                  <option value="Credito">Credito</option>
              </Input>
            </Col>
            <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="select"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                >
                  <option value="">Plazo</option>
                  <option value="0">0</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                  <option value="150">150</option>
                  <option value="180">180</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Reg Fiscal</Label>
                <Input
                  type="select"
                  placeholder="RegFiscal"
                  value={regFiscal}
                  required
                  onChange={(e) => {
                    setRegFiscal(e.target.value);
                  }}
                >
                  <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Fisicas con Actividad Empresarial</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
                </Input>
              </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Grupo</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedGrupoEdit}
                required
                onChange={(e) => {
                  setSelectedGrupoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Grupo</option>
                {grupos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Departamento</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedDepartamentoEdit}
                required
                onChange={(e) => {
                  setSelectedDepartamentoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Departamento</option>
                {departamentos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={3}>
                <Label className="mr-sm-2">Almacen</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedAreaEdit}
                  required
                  onChange={(e) => {
                    setSelectedAreaEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un almacen</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>

            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editClientes}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoClientes;
