import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "qrcode-js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ApartadosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_ARTICULOS_VENTAS_TIENDAS =
    process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_SUCURSALES = process.env.REACT_APP_URL_SUCURSALES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CAMARONES = process.env.REACT_APP_AREAS_CAMARONES;
  const AREAS_TOMATLAN = process.env.REACT_APP_AREAS_TOMATLAN;
  const AREAS_COCO = process.env.REACT_APP_AREAS_COCO;
  const AREAS_SN_ANTONIO = process.env.REACT_APP_AREAS_SN_ANTONIO;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;
  const URL_VENTAS_TOMATLAN = process.env.REACT_APP_URL_VENTAS_TOMATLAN;
  const URL_VENTAS_COCO = process.env.REACT_APP_URL_VENTAS_COCO;
  const URL_VENTAS_SN_ANTONIO = process.env.REACT_APP_URL_VENTAS_SN_ANTONIO;
  const URL_VENTAS_PLAZA = process.env.REACT_APP_URL_VENTAS_PLAZA;
  const URL_VENTAS_TOMATLAN_FISCAL =
    process.env.REACT_APP_URL_VENTAS_TOMATLAN_FISCAL;
  const URL_VENTAS_COCO_FISCAL = process.env.REACT_APP_URL_VENTAS_COCO_FISCAL;
  const URL_VENTAS_SN_ANTONIO_FISCAL =
    process.env.REACT_APP_URL_VENTAS_SN_ANTONIO_FISCAL;
  const URL_VENTAS_PLAZA_FISCAL = process.env.REACT_APP_URL_VENTAS_PLAZA_FISCAL;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [selectedColaboradorInput, setSelectedColaboradorInput] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [cliente, setCliente] = useState("");
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState([]);
  const [paqueteria, setPaqueteria] = useState("");
  const [sucursal, setSucursal] = useState("");
  const [inventarios, setInventarios] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulosUnicos, setArticulosUnicos] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [condPago, setCondPago] = useState("Contado");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [nomina, setNomina] = useState(0);
  const [cortesia, setCortesia] = useState(0);
  const [fiscal, setFiscal] = useState("No");
  const [saldo, setSaldo] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInv: "",
      cantInv: 0,
      articulos: "",
      articulosNombre: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
      linea: "",
      familia: "",
      marca: "",
      precioEsp:"No",
      venta:0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  useMemo(() => {
    if (user.areas) {
      if (user.areas == AREAS_TOMATLAN || user.areas == AREAS_PLAZA) {
        setSelectedArea(AREAS_PLAZA);

        axios
          .get(`${URL_INVENTARIOS}AcumuladoAlmacen/${AREAS_PLAZA}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allInventarios = res.data;
            setInventarios(allInventarios);
            setSelectedAreaName(res.data[0].area);
            let result = allInventarios.map((a) => a.idArticulo);
            setIdsArticulos(result);

            let unicos = allInventarios.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t.idArticulo === value.idArticulo)
            );
            setArticulosUnicos(unicos);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setSelectedArea(user.areas);
        axios
          .get(`${URL_INVENTARIOS}AcumuladoAlmacen/${selectedArea}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allInventarios = res.data;
            setInventarios(allInventarios);
            setSelectedAreaName(res.data[0].area);
            let result = allInventarios.map((a) => a.idArticulo);
            setIdsArticulos(result);

            let unicos = allInventarios.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t.idArticulo === value.idArticulo)
            );
            setArticulosUnicos(unicos);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      axios
        .get(`${URL_CLIENTES}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allClientes = res.data;
          setClientes(allClientes);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setSelectedColaboradorInput(user.id);
    setSelectedColaborador(user.id);
    setSelectedColaboradorName(user.nombre + " " + user.apellido);



    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedArea, selectedColaborador]);

  useEffect(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveVenta = async (event) => {
    event.preventDefault();

    let efeGuarda = parseFloat(efectivo) + parseFloat(saldo);

    let prefijo;
    if (fiscal == "Si") {
      if (user.areas == AREAS_SN_ANTONIO) {
        prefijo = "SAF";
      } else if (user.areas == AREAS_TOMATLAN) {
        prefijo = "TMF";
      } else if (user.areas == AREAS_COCO) {
        prefijo = "CCF";
      } else if (user.areas == AREAS_PLAZA) {
        prefijo = "PLF";
      } 
    } else {
      if (user.areas == AREAS_SN_ANTONIO) {
        prefijo = "SA";
      } else if (user.areas == AREAS_TOMATLAN) {
        prefijo = "TM";
      } else if (user.areas == AREAS_COCO) {
        prefijo = "CC";
      } else if (user.areas == AREAS_PLAZA) {
        prefijo = "PL";
      } 
    }

    // if (condPago == "Contado" && saldo > 0) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "El Pago no coincide con el Total",
    //   });
    // } else {
      if (!value._id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Selecciona un Cliente",
        });
      } else {
        toggleProgreso();
        let totalPedido = inputFields.length;

        try {
          await axios
            .post(
              URL_VENTAS_TIENDAS,
              {
                fecha,
                clientes: value._id,
                user: selectedColaborador,
                total_general: total_general.toFixed(2),
                observaciones,
                total_cantidad,
                paqueteria,
                areas: user.areas,
                sucursales: sucursal,
                comisionTDC,
                comisionFlete,
                subTotal: subTotal.toFixed(2),
                iva: iva.toFixed(2),
                impuestos: selectedImpuesto,
                cond_pago: condPago,
                fiscal,
                saldo: saldo.toFixed(2),
                cortesia,
                prefijo,
                consecutivo: 0,
                entregado: "No",
                descuento,
                areaSurtido: selectedArea,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((data) => {
              // PDFOC(data.data._id, data.data.idVentaTienda);

              if (fiscal == "Si") {
                if (user.areas == AREAS_SN_ANTONIO) {
                  axios
                    .post(
                      URL_VENTAS_SN_ANTONIO_FISCAL,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasSnAntonioFiscal,
                        prefijo
                      );
                    });
                } else if (user.areas == AREAS_TOMATLAN) {
                  axios
                    .post(
                      URL_VENTAS_TOMATLAN_FISCAL,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasTomatlanFiscal,
                        prefijo
                      );
                    });
                } else if (user.areas == AREAS_COCO) {
                  axios
                    .post(
                      URL_VENTAS_COCO_FISCAL,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasCocoFiscal,
                        prefijo
                      );
                    });
                } else if (user.areas == AREAS_PLAZA) {
                  axios
                    .post(
                      URL_VENTAS_PLAZA_FISCAL,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasPlazaFiscal,
                        prefijo
                      );
                    });
                }
              } else {
                if (user.areas == AREAS_SN_ANTONIO) {
                  axios
                    .post(
                      URL_VENTAS_SN_ANTONIO,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasSnAntonio,
                        prefijo
                      );
                    });
                } else if (user.areas == AREAS_TOMATLAN) {
                  axios
                    .post(
                      URL_VENTAS_TOMATLAN,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasTomatlan,
                        prefijo
                      );
                    });
                } else if (user.areas == AREAS_COCO) {
                  axios
                    .post(
                      URL_VENTAS_COCO,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasCoco,
                        prefijo
                      );
                    });
                } else if (user.areas == AREAS_PLAZA) {
                  axios
                    .post(
                      URL_VENTAS_PLAZA,
                      {
                        ventasTiendas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      PDFOC(
                        data.data.ventasTiendas[0],
                        data.data.idVentasPlaza,
                        prefijo
                      );
                    });
                } 
              }

              // if (condPago == "Contado") {
              //   axios.post(
              //     URL_ABONOS_TIENDAS,
              //     {
              //       fecha,
              //       total: total_general,
              //       areas: user.areas,
              //       clientes: value._id,
              //       ventasTiendas: data.data._id,
              //       efectivo: efeGuarda,
              //       tdc,
              //       transfer,
              //       cortesia,
              //       nomina,
              //     },
              //     {
              //       headers: {
              //         Authorization: `Bearer: ${localStorage.getItem(
              //           "app_token"
              //         )}`,
              //       },
              //     }
              //   );
              // }
              inputFields.map((a) => {
                if (a.articulos != "" && a.cantidad != 0) {
                  axios
                    .post(
                      `${URL_ARTICULOS_VENTAS_TIENDAS}Apartado`,
                      {
                        ventasTiendas: data.data._id,
                        articulos: a.articulos,
                        cantidad: parseFloat(a.cantidad),
                        precio: parseFloat(a.precio),
                        total: parseFloat(a.total),
                        inventarios: a.idInv,
                        entregado:"No"
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      totalPedido = totalPedido - 1;
                      if (totalPedido == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Venta de Tienda",
                              detalle: `${value._id} ${total_general}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              `success`
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                            });
                            console.log(error);
                          });
                      }
                    });
                } else {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Venta de Tienda",
                          detalle: `${value._id} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setModalProgreso(false);
                        // clear();
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }
                }
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
        }
      }
    // }
  };

  async function PDFOC(idPDFOC, idVentaTienda, prefijo) {
    let cajas = inputFields.map((c) => parseFloat(c.cajas));
    let TC = cajas.reduce((t, total, index) => t + total, 0);

    let LA = inputFields.length * 1.5;
    let largoTotal = LA + 4 + 9;

    const data = inputFields.map((a) => {
      return [
        a.articulosNombre + " " + a.linea + " " + a.familia + " " + a.marca,
        new Intl.NumberFormat("en-US").format(a.cantidad),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.precio),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total),
      ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("p", "cm", [8, largoTotal]);
    var base64 = qrcode.toDataURL(idPDFOC);
    var img3 = new Image();
    img3.src = base64;

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";

    doc.addImage(img2, "png", 5, 1, 2, 2);

    doc.setFontSize(6);
    doc.text(`Tandera SA de CV / RFC: TAN090216V4A`, 0.3, 1);
    doc.text(`Mixcalco 32`, 0.3, 1.3);
    doc.text(`Colonia: Centro CDMX`, 0.3, 1.6);
    doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, 0.3, 1.9);
    doc.text(`Fecha: ${fecha}`, 0.3, 2.2);
    doc.text(
      `Nota # ${prefijo}${idVentaTienda} / Almacen: ${selectedAreaName}`,
      0.3,
      2.5
    );
    doc.text(
      `Piezas: ${new Intl.NumberFormat("en-US").format(
        total_cantidad
      )} / Cajas ${TC.toFixed(2)}`,
      0.3,
      2.8
    );
    doc.text(`Cliente: ${value.nombre_comercial}`, 0.3, 3.1);
    doc.text(`Vendedor: ${selectedColaboradorName}`, 0.3, 3.4);
    doc.autoTable({
      head: [["Articulo", "Cant", "Precio", "Total"]],
      body: dataPDFLimpia,
      styles: {
        fontSize: 8,
      },
      startY: 3.8,
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      foot: [
        [
          "",
          { content: "SubTotal", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(subTotal),
        ],
        [
          "",
          {
            content: `Descuento ${descuento}%`,
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(descuentoNumero),
        ],
        [
          "",
          { content: `Comision`, colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(iva),
        ],
        [
          "",
          { content: "Comision TDC", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionTDC),
        ],
        [
          "",
          { content: "Comision Flete", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionFlete),
        ],
        [
          "",
          { content: "Total", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(total_general),
        ],
        // ["Forma de Pago"],
        // ["Efectivo", "TDC", "Transfer", "Nomina", "Cortesia"],
        // [
        //   `$${new Intl.NumberFormat("en-US").format(efectivo)}`,
        //   new Intl.NumberFormat("en-US", {
        //     style: "currency",
        //     currency: "USD",
        //     maximumFractionDigits: 2,
        //   }).format(tdc),
        //   new Intl.NumberFormat("en-US", {
        //     style: "currency",
        //     currency: "USD",
        //     maximumFractionDigits: 2,
        //   }).format(transfer),
        //   new Intl.NumberFormat("en-US", {
        //     style: "currency",
        //     currency: "USD",
        //     maximumFractionDigits: 2,
        //   }).format(nomina),
        //   new Intl.NumberFormat("en-US", {
        //     style: "currency",
        //     currency: "USD",
        //     maximumFractionDigits: 2,
        //   }).format(cortesia),
        // ],
        // [`Cambio: $${new Intl.NumberFormat("en-US").format(saldo * -1)}`],
      ],
      showFoot: "lastPage",
    });
    doc.addImage(img3, "png", 0.3, doc.autoTable.previous.finalY - 6, 3, 3);

    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
    // window.open(doc.output("bloburl"), "_blank");
//doc.save(`Nota-${prefijo}${idVentaTienda}.pdf`);
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInv: "",
        cantInv: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        linea: "",
        familia: "",
        marca: "",
        precioEsp:"No",
        venta:0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let totalKG = values.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(cortesia);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer - nomina - cortesia);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (i.cantInv >= cantTemp || event.target.value == "") {
          i.cajas = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.cantInv >= event.target.value) {
          i.cantidad = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          let idInv = a._id;
          let cantInv = a.cantidad;
          let articulosNombre = a.nombre;
          let linea = a.linea;
          let familia = a.familia;
          let marca = a.marca;
          let precioEsp = a.precioEsp
          let venta = a.venta
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            idInv,
            cantInv,
            articulosNombre,
            linea,
            familia,
            marca,
            precioEsp,
            venta
          );
        }
      });
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    idInv,
    cantInv,
    articulosNombre,
    linea,
    familia,
    marca,
    precioEsp,
    venta
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        i.idInv = idInv;
        i.cantInv = cantInv;
        i.articulosNombre = articulosNombre;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.linea = linea;
        i.familia = familia;
        i.marca = marca;
        i.precioEsp = precioEsp
        i.venta = venta
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }

    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(cortesia) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer - nomina - cortesia);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(selectedCliente) {
    if (selectedCliente) {
      setValue(selectedCliente);

      axios
        .get(`${URL_CLIENTES}/${selectedCliente._id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allCliente = res.data;

          setCliente(allCliente);
          // setSelectedColaborador(allCliente.vendedor[0]._id);
          // setSelectedColaboradorName(
          //   allCliente.vendedor[0].nombre +
          //     " " +
          //     allCliente.vendedor[0].apellido
          // );
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`${URL_SUCURSALES}/clientes/${selectedCliente._id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allSucursales = res.data;
          setSucursales(allSucursales);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function jalaSucursal(value) {
    let split = value.split(",");
    let dir = split[0];
    let paq = split[1];

    setSelectedSucursal(value);
    setSucursal(dir);
    setPaqueteria(paq);
  }

  // function jalaColaborador(value) {
  //   let split = value.split(",");
  //   let id = split[0];
  //   let nombre = split[1];
  //   let apellido = split[2];

  //   setSelectedColaboradorInput(value);
  //   setSelectedColaborador(id);
  //   setSelectedColaboradorName(nombre + " " + apellido);
  // }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (e * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(cortesia);
    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer - nomina - cortesia);
    if (e > 0.01 || tdc > 0.01 || transfer > 0.01) {
      setFiscal("Si");
    } else {
      setFiscal("No");
    }
  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp =
      temp +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(cortesia);

    // setSubTotal(temp)
    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer - nomina - cortesia);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(e) +
      parseFloat(comisionFlete) -
      parseFloat(cortesia);
    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer - nomina - cortesia);
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(e) -
      parseFloat(cortesia);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer - nomina - cortesia);
  }

  function autorizaCambio() {
    if (password == randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        inventarios.map((a) => {
          if (a.idArticulo == event.target.value) {
            let idArticulo = a.idArticulo;
            let piezasPorCaja = a.piezasPorCaja;
            let precio = a.venta;
            let idInv = a._id;
            let cantInv = a.cantidad;
            let articulosNombre = a.nombre;
            let linea = a.linea;
            let familia = a.familia;
            let marca = a.marca;
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              precio,
              idInv,
              cantInv,
              articulosNombre,
              linea,
              familia,
              marca
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu inventario",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }


  function jalaCortesia(e) {
    setCortesia(e);
    setSaldo(total_general - efectivo - tdc - transfer - nomina - e);
  }

  function jalaEfectivo(e) {
    setEfectivo(e);
    setSaldo(total_general - e - tdc - transfer - nomina - cortesia);
  }

  function jalaTransfer(e) {
    setTransfer(e);
    setSaldo(total_general - efectivo - tdc - e - nomina - cortesia);
    if (e > 0.01 || tdc > 0.01 || selectedImpuesto > 0.01) {
      setFiscal("Si");
    } else {
      setFiscal("No");
    }
  }

  function jalaNomina(e) {
    setNomina(e);
    setSaldo(total_general - efectivo - tdc - e - transfer - cortesia);
  }

  function jalaTDC(e) {
    setTDC(e);
    setSaldo(total_general - efectivo - e - transfer - nomina - cortesia);
    if (e > 0.01 || selectedImpuesto > 0.01 || transfer > 0.01) {
      setFiscal("Si");
    } else {
      setFiscal("No");
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: "loyo258502@icloud.com",
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${selectedAreaName} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}.</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.ventasTiendas ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Apartado</h3>
          <Form onSubmit={saveVenta}>
            <Row>

                <>
                  <Col md={3}>
                    <Label className="mr-sm-2">Cliente</Label>
                    <Autocomplete
                      size="small"
                      value={value}
                      onChange={(event, selectedCliente) => {
                        jalaCliente(selectedCliente);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre_comercial}
                        </React.Fragment>
                      )}
                    />
                  </Col>


                  <Col md={1}>
                    <Label>Sucursal</Label>
                    <Input
                      type="select"
                      value={selectedSucursal}
                      required
                      onChange={(e) => {
                        jalaSucursal(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {sucursales
                        .sort((a, b) => (a.alias > b.alias ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={[a._id, a.paqueteria]}>
                              {a.alias}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Label>Paqueteria</Label>
                    <Input
                      type="text"
                      placeholder="Paqueteria"
                      value={paqueteria}
                      required
                      onChange={(e) => {
                        setPaqueteria(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Comision TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Comision TDC"
                      value={comisionTDC}
                      required
                      onChange={(e) => {
                        jalaComisionTDC(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Comision Flete</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Comision Flete"
                      value={comisionFlete}
                      required
                      onChange={(e) => {
                        jalaComisionFlete(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={selectedImpuesto}
                      onChange={(e) => {
                        calculaImpuesto(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Facturar</Label>
                    <Input
                      type="select"
                      value={fiscal}
                      // onChange={(e) => {
                      //   setFiscal(e.target.value);
                      // }}
                      disabled
                    >
                      <option value={"No"}>No</option>
                      <option value={"Si"}>Si</option>
                    </Input>
                  </Col>
                </>
              <Col md={1}>
                <Label>Cambio de Precio</Label>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={(e) => toggleCambiaPrecio()}
                >
                  Solicitar
                </Button>
              </Col>
            </Row>
            <Row>
              
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Sub Total</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Sub Total"
                  value={subTotal.toFixed(2)}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>IVA</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="IVA"
                  value={iva.toFixed(2)}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>Total</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Total"
                  value={total_general}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>Piezas</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas"
                  value={total_cantidad}
                  required
                  disabled
                />
              </Col>
              {/* {condPago == "Contado" ? (
                <Col md={1}>
                  <Label>Efectivo</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={efectivo}
                    required
                    onChange={(e) => {
                      jalaEfectivo(e.target.value);
                    }}
                  />
                </Col>
              ) : (
                <Col md={1}>
                  <Label>Efectivo</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={efectivo}
                    required
                    disabled
                  />
                </Col>
              )}

              {condPago == "Contado" ? (
                <Col md={1}>
                  <Label>TDC</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={tdc}
                    required
                    onChange={(e) => {
                      jalaTDC(e.target.value);
                    }}
                  />
                </Col>
              ) : (
                <Col md={1}>
                  <Label>TDC</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={tdc}
                    required
                    disabled
                  />
                </Col>
              )}

              {condPago == "Contado" ? (
                <Col md={1}>
                  <Label>Transfer</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={transfer}
                    required
                    onChange={(e) => {
                      jalaTransfer(e.target.value);
                    }}
                  />
                </Col>
              ) : (
                <Col md={1}>
                  <Label>Transfer</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={transfer}
                    required
                    disabled
                  />
                </Col>
              )}


              {user.cambiaPrecios ? (
                <Col md={1}>
                  <Label>Cortesia</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={cortesia}
                    required
                    onChange={(e) => {
                      jalaCortesia(e.target.value);
                    }}
                  />
                </Col>
              ) : cambiarPrecio ? (
                <Col md={1}>
                  <Label>Cortesia</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={cortesia}
                    required
                    onChange={(e) => {
                      jalaCortesia(e.target.value);
                    }}
                  />
                </Col>
              ) : (
                <Col md={1}>
                  <Label>Cortesia</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={cortesia}
                    required
                    disabled
                  />
                </Col>
              )}
              <Col md={1}>
                <Label>Saldo</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  value={saldo}
                  required
                  disabled
                />
              </Col> */}
            </Row>

              {user.areas == AREAS_SN_ANTONIO || user.areas == AREAS_COCO ?(
                <Row>
                          <Col md={2}>
                              <Label className="mr-sm-2">Almacen de Entrega</Label>
                              <Input
                                type="select"
                                value={selectedArea}
                                required
                                onChange={(e) => {
                                  setSelectedArea(e.target.value);
                                }}
                              >
                                <option value="0">Selecciona</option>
                                {areas
                                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                                  .map((a) => {
                                        if (
                                          a._id == AREAS_PLAZA ||
                                          a._id == user.areas
                                        ) {
                                          return <option value={a._id}>{a.name}</option>;
                                        }
                                  })}
                              </Input>
                            </Col>
                          </Row>
              ):undefined}
            

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Inventario</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas Por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>{a.nombre}</option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantInv"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantInv}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  {user.cambiaPrecios || inputField.precioEsp == "Si" ||  cambiarPrecio  ? (
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  ) : (
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        disabled
                      />
                    </Col>
                  )}

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/MenuTiendas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ApartadosTiendasCreate;
