import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function VentaDirectaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SUCURSALES = process.env.REACT_APP_URL_SUCURSALES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  // const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("650a334ab99f0b3555a8cf16");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [cliente, setCliente] = useState("");
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("");
  const [sucursal, setSucursal] = useState("");
  const [paqueteria, setPaqueteria] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(AREAS_CEDIS);
  const [selectedAreaName, setSelectedAreaName] = useState("CEDIS");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      cantInv: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      articulosNombre: "",
      observacionesArticulo: "NA",
      idInv: "",
      costoUnitario:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useMemo(() => {
    // axios
    //   .get(`${URL_ARTICULOS}Activos`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allArticulos = res.data;
    //     setArticulos(allArticulos);
    //     let result = allArticulos.map((a) => a._id);
    //     setIdsArticulos(result);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(`${URL_CLIENTES}ActivosCEDIS`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
        console.log("hola", allClientes)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      if(selectedArea){
    axios
      .get(`${URL_INVENTARIOS}SurtirVentaDirecta`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setInventarios(allDisponible);

        setArticulos(allDisponible);
        // let result = allDisponible.map((a) => a.idArticulo);
        // setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [selectedArea]);

  const savePedido = async (event) => {
    event.preventDefault();

          if (selectedCliente != "" && selectedArea != "") {
            setValidaBoton(false);
            toggleProgreso();
            let totalPedido = inputFields.length;
            try {
              await axios
                .post(
                  URL_PEDIDOS,
                  {
                    fecha,
                    clientes: selectedCliente,
                    colaboradores: selectedColaborador,
                    total_general: parseFloat(total_general).toFixed(2),
                    observaciones,
                    total_cantidad,
                    paqueteria,
                    areas: selectedArea,
                    sucursales: sucursal,
                    comisionTDC,
                    comisionFlete,
                    subTotal: parseFloat(subTotal).toFixed(2),
                    iva: parseFloat(iva).toFixed(2),
                    impuestos: selectedImpuesto,
                    surtido: "Si",
                    autorizadoApartado: "Si",
                    autorizadoSurtido: "Si",
                    editado: "No",
                    fechaModificado: fecha,
                    creado: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(async (dataPedido) => {
                  await axios
                    .post(
                      URL_SURTIDOS,
                      {
                        fecha,
                        pedidos: dataPedido.data._id,
                        observaciones,
                        user: user.id,
                        clientes: selectedCliente,
                        areas: selectedArea,
                        total_general: parseFloat(total_general).toFixed(2),
                        saldo: parseFloat(total_general).toFixed(2),
                        subTotal: parseFloat(subTotal).toFixed(2),
                        iva: parseFloat(iva).toFixed(2),
                        impuestos: selectedImpuesto,
                        is_active: "Si",
                        colaboradores: selectedColaborador,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(async (dataSurtido) => {
                      
  
                      inputFields.map((a) => {
                        if (a.articulos != "" && a.cantidad != 0) {
                          axios
                            .post(
                              URL_ARTICULOS_PEDIDO,
                              {
                                pedidos: dataPedido.data._id,
                                articulos: a.articulos,
                                cantidad: parseFloat(a.cantidad),
                                pendiente_surtir: parseFloat(a.cantidad),
                                surtido: 0,
                                precio: parseFloat(a.precio).toFixed(2),
                                total: parseFloat(a.total).toFixed(2),
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then((dataArtPed) => {
                              axios
                                .post(
                                  URL_ARTICULOS_SURTIDOS,
                                  {
                                    surtidos: dataSurtido.data._id,
                                    fecha,
                                    inventarios: a.idInv,
                                    cantidad: parseFloat(a.cantidad),
                                    articulos: a.articulos,
                                    pedidos: dataPedido.data._id,
                                    articulosPedido: dataArtPed.data._id,
                                    costoUnitario: a.costoUnitario,
                                    areas: selectedArea,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer: ${localStorage.getItem(
                                        "app_token"
                                      )}`,
                                    },
                                  }
                                )
  
                                .then((data) => {
                                  totalPedido = totalPedido - 1;
                                  if (totalPedido == 0) {
                                    axios
                                      .post(
                                        URL_LOGS,
                                        {
                                          tipo: "Crear Pedido",
                                          detalle: `${value._id} ${total_general}`,
                                          user: user.id,
                                        },
                                        {
                                          headers: {
                                            Authorization: `Bearer: ${localStorage.getItem(
                                              "app_token"
                                            )}`,
                                          },
                                        }
                                      )
                                      .then(() => {
                                        Swal.fire(
                                          "Good job!",
                                          "Creado con exito",
                                          `success`
                                        );
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000);
                                      });
                                  }
                                })
                                .catch((error) => {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    // footer: `${error.response.data}`,
                                  });
                                  console.log(error);
                                  setValidaBoton(true);
                                });
                            });
                        } else {
                          totalPedido = totalPedido - 1;
                          if (totalPedido == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Pedido",
                                  detalle: `${value._id} ${total_general}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  `success`
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              });
                          }
                        }
                      });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        // footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Faltan datos, favor de revisar",
            });
          }

  };


  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        cantInv: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        observacionesArticulo: "NA",
        idInv: "",
        costoUnitario:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  };


  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.cantInv >= parseFloat(event.target.value) * parseFloat(i.piezasPorCaja)){
          i.cajas = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
       
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.cantInv >= event.target.value){
          i.cantidad = event.target.value;
          i.cajas = (
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)
          ).toFixed(2);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
        
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    if (
      inputFields.filter((e) => e.articulos == selectedArticulo.idArticulo).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulosNombre = "";
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
          let idArticulo = selectedArticulo.idArticulo;
          let piezasPorCaja = selectedArticulo.piezasPorCaja;
          let articulosNombre = selectedArticulo.nombre;
          let idInv = selectedArticulo._id;
          let costoUnitario = selectedArticulo.costoUnitario
          let precio = selectedArticulo.precio
          let precioOriginal= selectedArticulo.precio
          let cantInv = selectedArticulo.cantInv
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            precioOriginal,
            articulosNombre,
            idInv,
            costoUnitario, 
            cantInv
          );
    }
  }


  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre,
    idInv,
    costoUnitario,
    cantInv
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.precioOriginal = precioOriginal;
        i.idInv = idInv;
        i.costoUnitario = costoUnitario
        i.cantInv = cantInv
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);

  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp = temp + parseFloat(comisionTDC) + parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
  }

  const options = articulos.map((option) => {
    const junta = option.codigo + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {
    // setInputFields([
    //   {
    //     id: uuidv4(),
    //     articulos: "",
    //     piezasPorCaja: 0,
    //     cajas: 0,
    //     cantidad: 0,
    //     precio: 0,
    //     precioOriginal: 0,
    //     total: 0,
    //     articulosNombre: "",
    //     observacionesArticulo: "NA",
    //     idInv: "",
    //     costoUnitario:0
    //   },
    // ]);

    setSelectedCliente(cliente);

    axios
      .get(`${URL_CLIENTES}/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorName(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
          })
          .catch((err) => {
            console.log(err);
          });

          axios
      .get(`${URL_SUCURSALES}/clientes/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSucursales = res.data;
        setSucursales(allSucursales);
      })
      .catch((err) => {
        console.log(err);
      });
      }

      function jalaSucursal(value) {
        let split = value.split(",");
        let dir = split[0];
        let paq = split[1];
    
        setSelectedSucursal(value);
        setSucursal(dir);
        setPaqueteria(paq);
      }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
      
    } else {
      setIva(0);
    }
   

    setTotalGeneral(
      (e * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );


  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }

    let temp = (selectedImpuesto * subTotal) / 100 +
    subTotal +
    parseFloat(e) +
    parseFloat(comisionFlete) -
    parseFloat((descuentoNumero * subTotal) / 100)
    
    setTotalGeneral(temp);

  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }

        let temp = (selectedImpuesto * subTotal) / 100 +
    subTotal +
    parseFloat(e) +
    parseFloat(comisionTDC) -
    parseFloat((descuentoNumero * subTotal) / 100)

    setTotalGeneral(temp);

  }


  function jalaArea(e){
    setSelectedArea(e);
    areas.map((a)=>{
      if(a._id == e){
        setSelectedAreaName(a.name)
      }
    })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Venta Directa</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  jalaCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Sucursal</Label>
              <Input
                type="select"
                value={selectedSucursal}
                onChange={(e) => {
                  jalaSucursal(e.target.value);
                }}
              >
                <option value="">Selecciona una Sucursal</option>
                {sucursales
                  .sort((a, b) => (a.alias > b.alias ? 1 : -1))
                  .map((a) => {
                    if(a.is_active == "Si"){
                    return (
                      <option value={[a._id, a.paqueteria]}>{a.alias}</option>
                    )
                  }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                // onChange={(e) => {
                //   setSelectedColaborador(e.target.value)
                // }}
                disabled
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                type="text"
                placeholder="Paqueteria"
                value={paqueteria}
                required
                onChange={(e) => {
                  setPaqueteria(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  jalaArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if(a.name == "Plaza"){
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            <Col md={1}>
              <Label>Comision TDC</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col>
            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />

          {/* Tabla Articulos */}

          <Table borderless>
            <thead>
              <tr>
                <th>Buscador</th>
                <th>Articulo</th>
                <th style={{ maxWidth: "60px" }}>Inventario</th>
                <th style={{ maxWidth: "60px" }}>Cajas</th>
                <th style={{ maxWidth: "60px" }}>Pzs x Caja</th>
                <th style={{ maxWidth: "60px" }}>Cantidad</th>
                <th style={{ maxWidth: "60px" }}>Precio Original</th>
                <th style={{ maxWidth: "60px" }}>Precio</th>
                <th style={{ maxWidth: "80px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField) => (
                <tr>
                  {/* <td style={{ maxWidth: "120px" }}>
                    <Input
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </td> */}
                  <td>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticulo(inputField.id, selectedArticulo);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </td>
                  <td>
                    <Input
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </td>
                  {/* <td style={{ maxWidth: "60px" }}>
                    <Input type="select" disabled>
                      {inventarios.map((a) => {
                        if (inputField.articulos == a.idArticulo) {
                          return <option>{a.cantidad}</option>;
                        }
                      })}
                    </Input>
                  </td> */}
                  <td>
                    <Input
                      name="cantInv"
                      type="string"
                      placeholder="cantInv"
                      value={inputField.cantInv}
                      disabled
                    />
                  </td>
                   <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                    ):(
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      disabled
                    ></Input>
                    )}
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                    ):(
                      <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      disabled
                    />
                    )}
                    
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      // bsSize="sm"
                      name="precioOriginal"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="PrecioOriginal"
                      value={inputField.precioOriginal}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      // style={
                      //   inputField.precioOriginal - 50 > inputField.precio
                      //     ? { backgroundColor: "#ed8c8c" }
                      //     : null
                      // }
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </td>
                  <td style={{ maxWidth: "80px" }}>
                    <Input
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="total"
                      value={inputField.total}
                      disabled
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br />

          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoSurtidos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

         
        </div>
      ) : undefined}
    </>
  );
}

export default VentaDirectaCreate;
